import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAllocateBudgetList } from '../../../helper/Api';
import DataTable from 'react-data-table-component';
import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import './BudgetAllocationList.css';
const BudgetAllocationList = (listType) => {
    const { authData } = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const fetchBudgets = async (payload) => {
        try {
            console.log(payload);
            const { data } = await getAllocateBudgetList(payload);
            setData(data.result);
        } catch (error) {
            setData([]);
            console.log(error);
        }
    }
    useEffect(() => {
        fetchBudgets({
            approval: listType.approval,
            tier: listType.tier,
            department_id: listType.departmentId || authData.user.department_id,
            from_date: listType.fromDate,
            to_date: listType.toDate,
            search_str: listType.searchStr,
        });
    }, [listType.tier, listType.departmentId, listType.fromDate, listType.toDate, listType.searchStr])
    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };
    const columns = [
        {
            name: 'L2 Approval',
            selector: (row) => row.l2_status == null ? "Pending" : row.l2_status == 1 ? "Approved" : "Rejected",
            center: true
        },
        {
            name: 'L3 Approval',
            selector: (row) => row.l3_status == null ? "Pending" : row.l3_status == 1 ? "Approved" : "Rejected",
            center: true
        },
        {
            name: 'Budget Order No',
            selector: (row) => <b>{row.budget_order_no}</b>,
            width: "150px",
            center: true

        },
        {
            name: 'Budget Date',
            selector: (row) => {
                return (<b>{row.budget_order_date}</b>);
            },
            sortable: true,
            width: "150px",
            center: true,
        },
        {
            name: 'Provision Budget',
            selector: (row) => {
                return (<b>{row.total_scheme_provisional_allocation}</b>);
            },
            sortable: true,
            width: "200px",
            center: true
        },
        {
            name: 'Release Budget',
            selector: (row) => {
                return (<b>{row.total_scheme_released_allocation}</b>);
            },
            sortable: true,
            width: "200px",
            center: true
        },
        {
            name: 'Scheme Id',
            selector: (row) => row.scheme_id,
            wrap: true,
            sortable: true,
            width: "200px",
            center: true
        },
        {
            name: 'Scheme Name',
            selector: (row) => <b>{row.name}</b>,
            wrap: true,
            width: "300px",
        },
        {
            name: 'Total department',
            selector: (row) => <b>{row.total_departments}</b>,
            wrap: true,
            width: "150px",
            center: true
        },
        {
            name: 'Action',
            width: "200px",
            selector: (row) => {
                return (
                    <>
                        <div className='user_action'>
                            <span className='pr-4'><Link to={`/view-allocate-budget/${row.id}`}><AiOutlineEye /></Link></span>
                            {((Number(row.l2_status) === 0 || Number(row.l3_status) === 0) && authData.user.user_type === 'l1') && (
                                <span className='pr-4'>
                                    <Link to={`/edit-allocate-budget/${row.id}`}>
                                        <FaEdit />
                                    </Link>
                                </span>
                            )}
                        </div>
                    </>
                );
            },
        }
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}
export default BudgetAllocationList
