import React from 'react'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './Sidebar.css';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'

const Sidebar = () => {

    const [open, setOpen] = React.useState(true);
    const [FundReleaseOpen, SetFundReleaseOpen] = React.useState(false);
    const [reportsOpen, SetreportsOpen] = React.useState(false);
    const [reconciliatinOpen, SetReconciliatinOpen] = React.useState(false);
    const [depositoryOpen, setDepositoryOpen] = React.useState(false);
    // const [budgetReapOpen, setBudgetReapOpen] = React.useState(false);
    const [beneficiaryOpen, setBeneficiaryOpen] = React.useState(false);
    const [bankSchemeOpen, setBankSchemeOpen] = React.useState(false)
    const [InvoiceOpen, setInvoiceOpen] = React.useState(false)
    const [ppaOpen, setppaOpen] = React.useState(false)
    // const [user_type, set_User_type] = React.useState('');
    const { authData } = useSelector((state) => state.authData);
    // const [isMulti, setIsMulti] = useState();
    const isMulti = React.useRef(authData.user.department_details.is_multi === '0' ? false : true);
    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickFundRealse = () => {
        SetFundReleaseOpen(!FundReleaseOpen)
    }
    const handleClickReports = () => {
        SetreportsOpen(!reportsOpen)
    }

    const handleClickreconciliation = () => {
        SetReconciliatinOpen(!reconciliatinOpen)
    }
    const handleClickdepository = () => {
        setDepositoryOpen(!depositoryOpen)
    }

    // const handleClickBudgetReappropriation = () => {
    //     setBudgetReapOpen(!budgetReapOpen)
    // }
    const handleClickBankScheme = () => {
        setBankSchemeOpen(!bankSchemeOpen)
    }
    const handleClickInvoice = () => {
        setInvoiceOpen(!InvoiceOpen);
    };
    const handleClickBeneficiaryManagement = () => {
        setBeneficiaryOpen(!beneficiaryOpen)
    }
    const handleClickPPA = () => {
        setppaOpen(!ppaOpen)
    }
    // useEffect(() => {
    //     set_User_type(authData.user.user_type);
    // }, [])
    const location = useLocation();

    const isActive = (url) => {
        return location.pathname === url;
    };
    // const isActiveStatus = (search) => {
    //     return location.search === search; // Check if the current search matches the provided search
    // };


    return (
        <div>
            <List
                sx={{ width: '100%', maxWidth: 360, }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader" className="logoWrapper">
                        {/* <img src={logo} alt="logo" className="logo" /> */}
                    </ListSubheader>
                }
                className='sidebar'>   
                {/* <ListSubheader component="div" className="department-header" style={{ marginLeft: 40, background: 'none' }}>
                {authData?.user?.department_details?.user_type || 'Department Code'}
                </ListSubheader>  */}
                {/*-------------------------------------Dashboard---------------------------------*/}
                <Link to='/' className={isActive('/') ? 'active' : ""}>
                    <ListItemButton className='sidbar_linkWrapper'>
                        <ListItemText primary="Dashboard" className='sidebarLinkText' />
                    </ListItemButton>
                </Link>
                {/*-------------------------------------Department Management---------------------------------*/}
                {isMulti.current && <>
                <ListItemButton onClick={handleClickBankScheme}
                    className={bankSchemeOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Department Management" className='sidebarLinkText' />
                    {bankSchemeOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={bankSchemeOpen} timeout="auto" unmountOnExit
                    className={bankSchemeOpen ? 'subMenuWrapper subMenuWrapper_bottom_border' : ''}>
                    <List component="div" disablePadding>
                        <Link to='/departments' className={isActive('/departments') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Department" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/childAccountList' className={isActive('/childAccountList') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Child Bank Accounts " className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/schemeAccountList' className={isActive('/schemeAccountList') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Child Scheme - Bank Accounts Mapping" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse></>
                }
                {/*-------------------------------------User Management---------------------------------*/}
                <Link to='/users' className={isActive('/users') ? 'active' : ""}>
                    <ListItemButton className='sidbar_linkWrapper'>
                        <ListItemText primary="User Management" className='sidebarLinkText' />
                    </ListItemButton>
                </Link>
                {/*-------------------------------------Budget Management---------------------------------*/}
                <ListItemButton onClick={handleClick}
                    className={open ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Budget Management" className='sidebarLinkText' />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit
                    className={open ? 'subMenuWrapper subMenuWrapper_bottom_border' : ''}>
                    <List component="div" disablePadding>
                        <Link to='/schemes?reportType=own' className={isActive('/schemes') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Schemes" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/budgets' className={isActive('/budgets') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Budgets" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/budget-reappropriate' className={isActive('/budget-reappropriate') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Revision Budget & Limit Allocation" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        {isMulti.current &&
                        <Link to='/allocate-budgets' className={isActive('/allocate-budgets') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Child Budget Limit Allocation" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        }
                        <Link to='/department/activity' className={isActive('/department/activity') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Budget Activity" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/department/subactivity' className={isActive('/department/subactivity') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Budget Sub-Activity" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>
                {/*-------------------------------------Approval & Payments---------------------------------*/}
                <ListItemButton onClick={handleClickFundRealse}
                    className={FundReleaseOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Approval & Payments" className='sidebarLinkText' />
                    {FundReleaseOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={FundReleaseOpen} timeout="auto" unmountOnExit
                    className={FundReleaseOpen ? 'subMenuWrapper subMenuWrapper_bottom_border' : ''}>
                    {/*-------------------------------------Invoice---------------------------------*/}
                    <List component="div" disablePadding>
                        <Link to='/fund-invoice-list'>
                            <ListItemButton sx={{ pl: 4 }} onClick={handleClickInvoice}
                                className={isActive('/fund-invoice-list') ? 'active' : ""}>
                                <ListItemText primary="Invoice (Vendor/Contract/Service Provider)" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/beneficiary-reimbursement/approval'>
                            <ListItemButton sx={{ pl: 4 }}
                                className={isActive('/beneficiary-reimbursement/approval') ? 'active' : ""}>
                                <ListItemText primary="DBT/Bulk/NACH" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText primary="Payroll/Salary" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        {isMulti.current &&
                        <Link to='/allocate-budget-approval' className={isActive('/allocate-budget-approval') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Child Budget Limit Allocation" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        }
                        <Link to='/royalty_labour_approval' className={isActive('/royalty_labour_approval') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Royalty & Labour Cess" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>
                {/*-------------------------------------Beneficiary Management---------------------------------*/}
                <ListItemButton onClick={handleClickBeneficiaryManagement}
                    className={beneficiaryOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Vendor/Contract/Service Provider Management" className='sidebarLinkText' />
                    {beneficiaryOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={beneficiaryOpen} timeout="auto" unmountOnExit
                    className={beneficiaryOpen ? 'subMenuWrapper subMenuWrapper_bottom_border' : ''}>
                    <List component="div" disablePadding>
                        <Link to='/beneficiary' className={isActive('/beneficiary') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Vendor's/Contract/Service Provider's" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/invoices?reportType=own' className={isActive('/invoices') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Vendor's/Contract/Service Provider's Payments" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Payment Deductions" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>
                {/* DBT/Bulk/Nach Management */}
                <Link to='/beneficiary-reimbursement' className={isActive('/beneficiary-reimbursement') ? 'active' : ""}>
                    <ListItemButton className='sidbar_linkWrapper'>
                        <ListItemText primary="DBT/Bulk/Nach Management" className='sidebarLinkText' />
                    </ListItemButton>
                </Link>

                <Link >
                    <ListItemButton className='sidbar_linkWrapper'>
                        <ListItemText primary="Payroll/Salary Management" className='sidebarLinkText' />
                    </ListItemButton>
                </Link>


                <ListItemButton onClick={handleClickreconciliation}
                    className={reconciliatinOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Reconciliation" className='sidebarLinkText' />
                    {reconciliatinOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={reconciliatinOpen} timeout="auto" unmountOnExit
                    className={reconciliatinOpen ? 'subMenuWrapper subMenuWrapper_bottom_border' : ''}>
                    <List component="div" disablePadding>
                        <Link to='/tds-it'
                            className={isActive('/tds-it') || isActive('/tds-it') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="TDS-IT" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/gst'
                            className={isActive('/gst') || isActive('/gst') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="TDS-GST" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/gis'
                            className={isActive('/gis') || isActive('/gis') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="GIS" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/nps'
                            className={isActive('/nps') || isActive('/nps') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="NPS" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/other'
                            className={isActive('/other') || isActive('/other') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Other Deductions" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/challan'
                            className={isActive('/challan') || isActive('/add-challan') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="IFMS Interest Challan's" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/royalty'
                            className={isActive('/royalty') || isActive('/add-royalty') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Royalty" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/labour'
                            className={isActive('/labour') || isActive('/add-labour') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Labour Cess" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>
                {/*-------------------------------------Depository---------------------------------*/}
                <ListItemButton onClick={handleClickdepository}
                    className={depositoryOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Depository" className='sidebarLinkText' />
                    {depositoryOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={depositoryOpen} timeout="auto" unmountOnExit
                    className={depositoryOpen ? 'subMenuWrapper subMenuWrapper_bottom_border' : ''}>
                    <List component="div" disablePadding>
                        <Link to='/add-depository'
                            className={isActive('/add-depository') || isActive('/add-depository') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Add Depository" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link to='/depository'
                            className={isActive('/depository') || isActive('/depository') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Depository" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>
                {/*-------------------------------------Print Payment Advice (PPA) ---------------------------------*/}
                <ListItemButton onClick={handleClickPPA}
                    className={ppaOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Print Payment Advice (PPA)" className='sidebarLinkText' />
                    {ppaOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={ppaOpen} timeout="auto" unmountOnExit
                    className={ppaOpen ? 'subMenuWrapper subMenuWrapper_bottom_border' : ''}>
                    <List component="div" disablePadding>
                        <Link to='/ppa' className={isActive('/ppa') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="List Print Payment Advice (PPA)" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>

                {/*-------------------------------------Reports---------------------------------*/}
                <ListItemButton onClick={handleClickReports}
                    className={reportsOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Reports" className='sidebarLinkText' />
                    {reportsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={reportsOpen} timeout="auto" unmountOnExit
                    className={reportsOpen ? 'subMenuWrapper subMenuWrapper_bottom_border' : ''}>
                    <Link to='/schemes' className={isActive('/schemes') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Scheme Balances" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/budgets' className={isActive('/budgets') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Budgets" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Revised Budget & Limit Allocation" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/scheme-subhead' className={isActive('/scheme-subhead') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Schemes Subhead" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Scheme Activity & Sub- Activity" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    {isMulti.current &&
                    <Link to='/allocate-budgets' className={isActive('/allocate-budgets') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Child Budget Limit Allocations" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>}
                    <Link>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="UC :Utilization Certificate" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/mpr-report' className={isActive('/mpr-report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="MPR :Monthly Progress Report" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/beneficiary-report' className={isActive('/beneficiary-report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Vendor/Contractor/Service Provider" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/beneficiary-reimbursement' className={isActive('/beneficiary-reimbursement') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Payments :DBT/Bulk/NACH" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link >
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Payments :Payroll / Salary" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/invoices' className={isActive('/invoices') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Payments :Invoices" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/royalty_report' className={isActive('/royalty_report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Payments :Royalty" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/labour_report' className={isActive('/labour_report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Payments :Labour Cess" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link >
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Payments :Penalty" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link >
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Payments : Withheld Amount" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/tds-it-report' className={isActive('/tds-it-report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="TDS-IT" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/gst-report' className={isActive('/gst-report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="TDS-GST" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/gis-report' className={isActive('/gis-report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="GIS" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/nps-report' className={isActive('/nps-report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="NPS" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link >
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Royalty" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link >
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Labour Cess" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/other-report' className={isActive('/other-report') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Other Deduction" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/accumulated-deduction' className={isActive('/accumulated-deduction') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Accumulated Deduction" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link >
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Penalty" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link >
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Withheld Amount" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                    <Link to='/interest' className={isActive('/interest') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="Bank Account Interest" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link>
                </Collapse>
                <Link to='/authoritys'>
                    <ListItemButton className='sidbar_linkWrapper'>
                        <ListItemText primary="Authority" className='sidebarLinkText' />
                    </ListItemButton>
                </Link>
                <br></br>
            </List>
        </div>
    )
}

export default Sidebar
