import React, { useState, useRef, useEffect } from 'react'
import './AddUser.css';
import finger_print_img from '../../assets/images/finger_print.png';
import { Alert, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_USER_START, ADD_USER_SUCCESS, ADD_USER_FAIL } from '../../RTK/Slices/UserSlice';
import { add_user, get_departmentDetail, get_departments, get_department_users, map_user } from '../../helper/Api';
import { useNavigate,Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import Select from 'react-select';
import { TextField, Autocomplete, CircularProgress, } from '@mui/material';
const AddUserForm = () => {
    const [options, setOptions] = useState([]);
    const [currentDepartment, setCurrentDepartment] = useState();
    const { authData } = useSelector((state) => state.authData);
    const { loading } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        user_type: null,
        added_by: authData.user.id,
        department_level: authData.user.department_details.department_level,
        department_id: authData.user.department_id,
        t2_department_id: null,
        t3_department_id: null,
        t4_department_id: null,
        user_id: null,
        parent_department_id:authData.user.department_details.parent_department_id
    });
    const [passwordError, setPasswordError] = useState('');
    const [departmentT2, setDepartmentT2] = useState([]);
    const [departmentT3, setDepartmentT3] = useState([]);
    const [departmentT4, setDepartmentT4] = useState([]);
    const selectRef = useRef(null);
    const [t1Details, sett1Details] = useState({});
    const [t2Details, sett2Details] = useState({});
    const [t3Details, sett3Details] = useState({});
    const [t4Details, sett4Details] = useState({});
    const isMulti = useRef(authData.user.department_details.is_multi === '0' ? false : true);
    const [error, setError] = useState({
        status: false,
        msg: ''
    });
    useEffect(() => {
        fetctDetails(formData.department_id);
        setCurrentDepartment(authData?.user.department_details?.department_level);
        setDefaultPageData();
        department_user();
    }, [])

    const setDefaultPageData = async () => {
        if (authData.user?.department_details?.department_level === 'T2') {
            await fetctDetails(authData.user.department_details.parent_department_id);
            await fetchDepartments('T2');
        }
        if (authData.user?.department_details?.department_level === 'T3') {
            await fetctDetails(1);
            await fetctDetails(authData.user.department_details.parent_department_id);
            await fetchDepartments('T2');
            await fetchDepartments('T3');
        }
    }

    const handleFormDataChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'department_level') {
            setFormData({ ...formData, [name]: value, t2_department_id: null, t3_department_id: null, t4_department_id: null })
        } else {
            setFormData({ ...formData, [name]: value })
        }
        if (name === 'password') {
            setPasswordError('');
        }
        if (name === 'department_level') {
            // setDepartment([])
            if (value == 'T1') {
                // setDepartmentT2([])
                fetchDepartments(value)
            }
            else if (value == 'T2') {
                sett2Details({})
                setDepartmentT2([])
                fetchDepartments(value)
            }
            else if (value == 'T3') {
                setDepartmentT3([])
                sett2Details({})
                sett3Details({})
                for (let item of ["T1", "T2", "T3"]) {
                    fetchDepartments(item)
                }
            }
            else if (value == 'T4') {
                sett2Details({})
                sett3Details({})
                sett4Details({})
                for (let item of ["T1", "T2", "T3", "T4"]) {
                    fetchDepartments(item)
                }
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        if (!validateForm()) {
            alert('Please fill in all fields correctly.');
            return;
        }
        try {
            dispatch(ADD_USER_START());
            let payload = { ...formData };
            if (formData.department_level === 'T2') {
                formData.department_id = formData.t2_department_id
            }
            if (formData.department_level === 'T3') {
                formData.department_id = formData.t3_department_id
            }
            if (formData.department_level === 'T4') {
                formData.department_id = formData.t4_department_id
            }
            delete payload.t2_department_id;
            delete payload.t3_department_id;
            delete payload.t4_department_id;
            if (payload.user_id && typeof payload.user_id === 'object') {
                payload.user_id = payload.user_id.id;
            }
            const add_user_response = await map_user(payload);
            let addUserResponseData = add_user_response.data;
            if (addUserResponseData.status) {
                //dispatch(ADD_USER_SUCCESS(addUserResponseData.users[0]));
                toast.success(addUserResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate('/users');
            } else {
                toast.error(addUserResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(ADD_USER_FAIL(addUserResponseData.message));
            }
        } catch (error) {
            toast.error("error getting while add user", {
                position: toast.POSITION.TOP_CENTER
            });
            dispatch(ADD_USER_FAIL('error getting while add user'));
        }
    }
    const validateForm = () => {
        // Validate each field individually
        console.log('formData', formData);
        if (
            // !formData.code.trim() ||
            // !formData.user_name.trim() ||
            // !formData.designation.trim() ||
            !formData.user_type.trim() ||
            !formData.user_id?.id.trim()
            // !formData.mobile.trim() ||
            // !formData.email.trim() ||
            // !formData.password.trim()
        ) {
            return false; // If any field is empty, return false
        }
        // Add more validation logic as needed
        return true; // All fields are valid
    };
    const fetchDepartments = async (value, parent_department_id = null) => {
        try {
            const departments = await get_departments({ department_level: value, parent_department_id });
            if (departments.data.status) {
                if (value === 'T2') {
                    if (authData.user?.department_details?.department_level === 'T2') {
                        setDepartmentT2(departments.data.result.filter(d => d.id === authData.user.department_id));
                        fetctDetails(authData.user.department_id);
                    } else if (authData.user?.department_details?.department_level === 'T3') {
                        setDepartmentT2(departments.data.result.filter(d => d.id === authData.user?.department_details?.parent_department_id));
                        fetctDetails(authData.user?.department_details?.parent_department_id);
                    } else {
                        departments.data.result.unshift({ id: null, name: '--Select T2 Department --' });
                        setDepartmentT2(departments.data.result);
                        // fetctDetails(departments.data.result[0].id);
                    }
                }
                if (value === 'T3') {
                    if (authData.user?.department_details?.department_level === 'T3') {
                        setDepartmentT3(departments.data.result.filter(d => d.id === authData.user.department_id));
                        fetctDetails(authData.user.department_id);
                    } else if(parent_department_id || authData.user?.department_details?.department_level === 'T2') {
                        departments.data.result.unshift({id: null, name: '-- Select T3 Department --'});
                        parent_department_id = parent_department_id ? parent_department_id : authData.user.department_id;
                        // setDepartmentT3(departments.data.result);
                        setDepartmentT3(departments.data.result.filter(d => d.id === null || d.parent_department_id === parent_department_id));
                        // fetctDetails(departments.data.result[0].id);
                    } else {
                        // departments.data.result.unshift({id: null, name: '-- Select T3 Department --'});
                        setDepartmentT3([{ id: null, name: '-- Select T3 Department --' }]);
                        // fetctDetails(departments.data.result[0].id);
                    }
                }
                if (value === 'T4') {
                    if (authData.user?.department_details?.department_level === 'T4') {
                        setDepartmentT4(departments.data.result.filter(d => d.id === authData.user.department_id));
                        fetctDetails(authData.user.department_id);
                    } else if(parent_department_id || authData.user?.department_details?.department_level === 'T3') {
                        departments.data.result.unshift({id: null, name: '-- Select T4 Department --'});
                        parent_department_id = parent_department_id ? parent_department_id : authData.user.department_id;
                        setDepartmentT4(departments.data.result.filter(d => d.id === null || d.parent_department_id === parent_department_id));
                        fetctDetails(departments.data.result[0].id);
                    } else {
                        // departments.data.result.unshift({id: null, name: '-- Select T4 Department --'});
                        setDepartmentT4([{id: null, name: '-- Select T4 Department --'}]);
                        // fetctDetails(departments.data.result[0].id);
                    }
                }

            }
        } catch (error) {
            console.log("departments");
        }
    }
    const fetctDetails = async (id, level = null) => {
        try {
            const departmentDetail = await get_departmentDetail({ id: id });
            const departObject = departmentDetail.data.result[0];
            if (departmentDetail.data.status) {
                if (departObject.department_level === 'T1' || level === 'T1') {
                    sett1Details(departObject);
                }
                if (departObject.department_level === 'T2' || level === 'T2') {
                    sett2Details(departObject);
                }
                if (departObject.department_level === 'T3' || level === 'T3') {
                    sett3Details(departObject);
                }
                if (departObject.department_level === 'T4' || level === 'T4') {
                    sett4Details(departObject);
                }
                setFormData(prevState => ({
                    ...prevState,
                    department_id: departObject.id || authData.user.department_id,
                }));
            }
        } catch (error) {
            console.log("departments");
        }
    }
    const handleDepartment = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
        fetctDetails(value);
        if (name === 't2_department_id' && (formData.department_level === 'T3' || formData.department_level === 'T4')) {
            sett3Details({})
            sett4Details({})
            fetchDepartments('T3', value);
        }
        if (name === 't3_department_id' && formData.department_level === 'T4') {
            // sett3Details({})
            sett4Details({})
            fetchDepartments('T4', value);
        }
    }
    const department_user = async () => {
        try {
            const departments = await get_department_users({ searchStr: "" });
            setOptions(departments.data.users);
            console.log("departments", departments);
        }
        catch (error) {
            console.log("department_user", error);
        }
    }
    const fetchOptions = async (query) => {
        if (query.length < 2) return;
        try {
            const departments = await get_department_users({ searchStr: query });
            setOptions(departments.data.users);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {

        }
    };
    const handleInputChange = (event, newInputValue) => {
        //setInputValue(newInputValue);
        fetchOptions(newInputValue);
    };
    const handleChange = (event, newValue) => {
        setFormData({
            ...formData,
            user_id: newValue,
        });
    };
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    {error.status && (
                        <Alert variant="danger" onClose={() => setError({ status: false, msg: '' })} dismissible>
                            {error.msg}
                        </Alert>
                    )}

                    <Form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <p>Add New User</p>
                                <div>
                                    <button type="button" className="btn btn-light">Cancel</button>
                                    <button type="submit" disabled={loading} className="btn btn-primary">
                                        {loading ? 'loading' : "ADD USER"}</button>
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        {isMulti.current && 
                                        <div className="form-group row p-2">
                                            <label className="col-sm-4 col-form-label">Department Type</label>
                                            <div className="col-sm-8">
                                                <select className="form-control"
                                                    name="department_level"
                                                    value={formData.department_level}
                                                    onChange={handleFormDataChange}
                                                    required>
                                                    {currentDepartment === 'T1' && <option value="T1">T1</option>}
                                                    {(currentDepartment === 'T1' || currentDepartment === 'T2') && <option value="T2">T2</option>}
                                                    {(currentDepartment === 'T1' || currentDepartment === 'T2' || currentDepartment === 'T3') && <option value="T3">T3</option>}
                                                    {(currentDepartment === 'T1' || currentDepartment === 'T2' || currentDepartment === 'T3' || currentDepartment === 'T4') && <option value="T4">T4</option>}
                                                </select>
                                            </div>
                                        </div>
                                        }
                                        {/* t1 details show */}
                                        <div className="table-responsive mt-4">
                                            <h6 class="department_level">T1 Department Details</h6>
                                            <table className="table table-bordered table-center">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Nomiclature</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{t1Details.code}</td>
                                                        <td>{t1Details.name}</td>
                                                        <td>{t1Details.nomiclature}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* t2 dropdown */}
                                        {(formData.department_level === 'T2' || formData.department_level === 'T3' || formData.department_level === 'T4') &&
                                            <div className="form-group row p-2">
                                                <label className="col-sm-4 col-form-label">Department T2</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control"
                                                        name="t2_department_id"
                                                        onChange={handleDepartment}
                                                        required>
                                                        {departmentT2.map(option => (
                                                            <option key={option.name} value={option.id}
                                                                selected={formData.t2_department_id == option.id ? true : false}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        }

                                        {((formData.department_level === 'T2' || formData.department_level === 'T3' || formData.department_level === 'T4')) &&
                                            <div className="table-responsive mt-4">
                                                <h6 class="department_level">T2 Department Details</h6>
                                                <table className="table table-bordered table-center">
                                                    <thead>
                                                        <tr>
                                                            <th>Code</th>
                                                            <th>Name</th>
                                                            <th>Nomiclature</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t2Details.code}</td>
                                                            <td>{t2Details.name}</td>
                                                            <td>{t2Details.nomiclature}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        {((formData.department_level === 'T3' || formData.department_level === 'T4')) &&
                                            <div className="form-group row p-2">
                                                <label className="col-sm-4 col-form-label">Department T3</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control"
                                                        name="t3_department_id"
                                                        onChange={handleDepartment}
                                                        required>
                                                        {departmentT3.map(option => (
                                                            <option key={option.name} value={option.id}
                                                                selected={formData.t3_department_id == option.id ? true : false}
                                                            >
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>}
                                        {(formData.department_level === 'T3' || formData.department_level === 'T4') &&
                                            <div className="table-responsive mt-4">
                                                <h6 class="department_level">T3 Department Details</h6>
                                                <table className="table table-bordered table-center">
                                                    <thead>
                                                        <tr>
                                                            <th>Code</th>
                                                            <th>Name</th>
                                                            <th>Nomiclature</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t3Details.code}</td>
                                                            <td>{t3Details.name}</td>
                                                            <td>{t3Details.nomiclature}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                        {(formData.department_level === 'T4') &&
                                            <div className="form-group row p-2">
                                                <label className="col-sm-4 col-form-label">Department T4</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control"
                                                        name="t4_department_id"
                                                        onChange={handleDepartment}
                                                        required>
                                                        {departmentT4.map(option => (
                                                            <option key={option.name} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>}

                                        {(formData.department_level === 'T4') &&
                                            <div className="table-responsive mt-4">
                                                <h6 class="department_level">T4 Department Details</h6>
                                                <table className="table table-bordered table-center">
                                                    <thead>
                                                        <tr>
                                                            <th>Code</th>
                                                            <th>Name</th>
                                                            <th>Nomiclature</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t4Details.code}</td>
                                                            <td>{t4Details.name}</td>
                                                            <td>{t4Details.nomiclature}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        {/* </div> */}
                                        {/* } */}



                                        {/* {(formData.department_level === 'T2' && authData.user.department_details.department_level !== 'T2') && <><h6 class="department_level">T2 Department Details</h6>
                                            <table className="table table-bordered table-center">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Nomiclature</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{t2Details.code}</td>
                                                        <td>{t2Details.name}</td>
                                                        <td>{t2Details.nomiclature}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                        } */}
                                        {/* {((formData.department_level === 'T3' && authData.user.department_details.department_level !== 'T3')) && <><h6 class="department_level">T3 Department Details</h6>
                                            <table className="table table-bordered table-center">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Nomiclature</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{t3Details.code}</td>
                                                        <td>{t3Details.name}</td>
                                                        <td>{t3Details.nomiclature}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                        } */}
                                        {/* {(formData.department_level === 'T4' && authData.user.department_details.department_level !== 'T4') && <><h6 class="department_level">T4 Department Details</h6>
                                            <table className="table table-bordered table-center">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Nomiclature</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{t4Details.code}</td>
                                                        <td>{t4Details.name}</td>
                                                        <td>{t4Details.nomiclature}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                        } */}
                                        {/* <div className="form-group row p-2">
                                            <label for="inputEmployee" className="col-sm-4 col-form-label">Employee
                                                Code<span className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" id="inputEmployee"
                                                    name="code"
                                                    value={formData.code}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    maxLength={10}
                                                    placeholder="Enter Employee Code" />
                                            </div>
                                        </div>

                                        <div className="form-group row p-2">
                                            <label for="inputUserid" className="col-sm-4 col-form-label">User Name<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputUserid"
                                                    name="user_name"
                                                    required
                                                    maxLength={30}
                                                    value={formData.user_name}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter User Name" />
                                            </div>
                                        </div>

                                        <div className="form-group row p-2">
                                            <label for="inputDesignation"
                                                className="col-sm-4 col-form-label">Designation<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputDesignation"
                                                    name="designation"
                                                    maxLength={30}
                                                    value={formData.designation}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    placeholder="Enter Designation" />
                                            </div>
                                        </div> */}
                                        <div className="form-group row p-2">
                                            <label for="inputLevel" className="col-sm-4 col-form-label">Level
                                                <span className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <select className="form-control " id="inputLevel"
                                                    name="user_type"
                                                    value={formData.user_type}
                                                    onChange={handleFormDataChange}
                                                    required
                                                >
                                                    <option value="">Select Level</option>
                                                    <option value="l1">l1</option>
                                                    <option value="l2">l2</option>
                                                    <option value="l3">l3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputLevel" className="col-sm-4 col-form-label">Users
                                                <span className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <Autocomplete
                                                    value={formData.user_id}
                                                    onInputChange={handleInputChange}
                                                    onChange={handleChange}
                                                    options={options}
                                                    getOptionLabel={(option) => {
                                                        if (!option || !option.email || !option.code) return '';
                                                        return `${option.email}-${option.code}`;
                                                    }}
                                                    isOptionEqualToValue={(option, value) => {
                                                        if (!value) return false;
                                                        return option.id === value?.id;
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: loading ? <CircularProgress color="inherit" size={10} /> : null,
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div className="mt-2">
                                                    <Link to="/add-department-user" className="text-primary">
                                                        Register a new user
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="form-group row p-2">
                                            <label for="inputMobile" className="col-sm-4 col-form-label">Mobile
                                                Number<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputMobile"
                                                    inputMode="numeric"
                                                    pattern="[6789][0-9]{9}"
                                                    name="mobile"
                                                    required
                                                    value={formData.mobile}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Mobile Number" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputEmail" className="col-sm-4 col-form-label">Email ID<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="email" className="form-control"
                                                    id="inputEmail"
                                                    name="email"
                                                    required
                                                    value={formData.email}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Email ID" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputPassword"
                                                className="col-sm-4 col-form-label">Password<span
                                                    className="text-danger">*</span>:</label>
                                            <div className="col-sm-8">
                                                <div className="input-group" id="show_hide_password">
                                                    <input className="form-control input_width"
                                                        type="password"
                                                        name="password"
                                                        required
                                                        pattern="[/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/]"
                                                        value={formData.password}
                                                        onChange={handleFormDataChange}
                                                        placeholder="Password" />
                                                    <span className="input-group-addon">
                                                        <a href=""><i className="fa fa-eye-slash"
                                                            aria-hidden="true" /></a>
                                                    </span>
                                                </div>
                                                {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                                <small className="form-text text-muted">
                                                    Password must be at least 8 characters long and contain at least one
                                                    uppercase letter, one lowercase letter, one digit, and one special
                                                    character.
                                                </small>

                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddUserForm
