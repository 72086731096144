import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import './sanctionOrderModal.css';

const AddBudgetPreview = ({ showPreview, setShowPreview, formData, handleSubmit, schemes, subheadsList, financialYears, total_scheme_budget, activity, subActivity}) => {

    const handleClose = () => setShowPreview(false);

    const get_scheme_details = (id) => {
        if (id) {
            const filter = schemes.filter((scheme) => scheme.id == id);
            if (filter.length > 0) {
                // console.log(filter);
                return filter[0];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const get_subhead_details = (id) => {
        if (id) {
            const filter = subheadsList.filter((subhead) => subhead.id == id);
            if (filter.length > 0) {
                // console.log(filter);
                return filter[0];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    const get_financial_year = (id) => {
        if (id) {
            const filter = financialYears.filter((fy) => fy.id == id);
            if (filter.length > 0) {
                return filter[0];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    return (
        <div>
            <Modal
                size="lg"
                show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Budget Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className='previewModalBody'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Scheme Name</th>
                                <th>Scheme Code.</th>
                                <th>Financial Year</th>
                                <th>Doc Uploaded</th>
                                <th>Total Scheme Budget</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{get_scheme_details(formData.scheme_id) ? get_scheme_details(formData.scheme_id).name : ""}</td>
                                <td>{get_scheme_details(formData.scheme_id) ? get_scheme_details(formData.scheme_id).code : ""}</td>
                                <td>{get_financial_year(formData.financial_year) ? get_financial_year(formData.financial_year).start_year + "-" + get_financial_year(formData.financial_year).end_year : ""}</td>
                                <td>{formData.attachment && formData.attachment.length ? "Yes" : "No"}</td>
                                <td>{total_scheme_budget.current}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <h6>Sub Head List:</h6>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>SubHead Name</th>
                                <th>SubHead Code</th>
                                <th>Budget Date</th>
                                <th>Budget</th>
                                <th>Provisional Budget</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                formData.sub_heads && formData.sub_heads.map((subhead, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{get_subhead_details(subhead.id) ? get_subhead_details(subhead.id).name : ""}</td>
                                                <td>{get_subhead_details(subhead.id) ? get_subhead_details(subhead.id).code : ""}</td>
                                                <td>{subhead.budget_date}</td>
                                                <td>{subhead.budget}</td>
                                                <td>{subhead.provisional_budget}</td>
                                            </tr>
                                            { formData.is_activity_enable=='1' && formData.monitoring_level=='1' && <tr>
                                                <td colSpan="6">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Activity ID</th>
                                                                <th>Subactivity ID</th>
                                                                <th>Provisional Budget</th>
                                                                <th>Release Budget</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {subhead.activity && subhead.activity.map((activityData, activityIndex) => {
                                                                if(activityData.activity_id && activityData.subactivity_id && activityData.provisional_budget && activityData.budget) {
                                                                    return <tr key={activityIndex}>
                                                                    <td>
                                                                        <select
                                                                            className="form-control"
                                                                            name='activity_id'
                                                                            value={activityData.activity_id} disabled={true}>
                                                                            <option value="">---Select---</option>
                                                                            {activity.map(e => (
                                                                                <option key={e.id} value={e.id}>{e.activity}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            className="form-control"
                                                                            name='subactivity_id'
                                                                            value={activityData.subactivity_id}
                                                                            disabled={true}>
                                                                            <option value="">---Select---</option>
                                                                            {findSubActivitiesById(activityData.activity_id).map(e => (
                                                                                <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name="provisional_budget"
                                                                            value={activityData.provisional_budget || ''}
                                                                            disabled={true}

                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name="budget"
                                                                            value={activityData.budget || ''}
                                                                            disabled={true}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                }
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>}
                                        </>
                                    );
                                })
                            }

                        </tbody>
                    </Table>
                    {
                        formData.newSub_heads.length ? 
                        <>
                        <h6>Create New Sub Head List:</h6>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>SubHead Name</th>
                                <th>SubHead Code</th>
                                <th>Budget Date</th>
                                <th>Budget</th>
                                <th>Provisional Budget</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                formData.newSub_heads && formData.newSub_heads.map((subhead, i) => {
                                    return (
                                        <><tr>
                                            <td>{i + 1}</td>
                                            <td>{subhead.name}</td>
                                            <td>{subhead.code}</td>
                                            <td>{subhead.budget_date}</td>
                                            <td>{subhead.budget}</td>
                                            <td>{subhead.provisional_budget}</td>
                                        </tr>
                                            <tr>
                                                <td colSpan="6">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Activity ID</th>
                                                                <th>Subactivity ID</th>
                                                                <th>Provisional Budget</th>
                                                                <th>Budget</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {formData.monitoring_level=='1' && subhead.activity && subhead.activity.map((activityData, activityIndex) => (
                                                                <tr key={activityIndex}>
                                                                    <td>
                                                                        <select
                                                                            className="form-control"
                                                                            name='activity_id'
                                                                            value={activityData.activity_id} disabled={true}>
                                                                            <option value="">---Select---</option>
                                                                            {activity.map(e => (
                                                                                <option key={e.id} value={e.id}>{e.activity}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            className="form-control"
                                                                            name='subactivity_id'
                                                                            value={activityData.subactivity_id}
                                                                            disabled={true}>
                                                                            <option value="">---Select---</option>
                                                                            {findSubActivitiesById(activityData.activity_id).map(e => (
                                                                                <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name="provisional_budget"
                                                                            value={activityData.provisional_budget || ''}
                                                                            disabled={true}

                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name="budget"
                                                                            value={activityData.budget || ''}
                                                                            disabled={true}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                        </>: ''
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Change
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default AddBudgetPreview