import React, { useState, useEffect, useRef } from 'react'
import Tools from '../../components/Tools/Tools'
import './Schemes.css';
import { SchemesList } from '../../components/BudgetAllocation/Schemes/SchemesList';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from "react-bootstrap";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";
import SchemeFilter from '../Filter/schemeFilter';
import {
    DELETE_SCHEME_START,
    DELETE_SCHEME_SUCCESS,
    GET_SCHEME_FAIL,
    GET_SCHEME_START,
    GET_SCHEME_SUCCESS
} from '../../RTK/Slices/SchemeSlice';
import { useLocation } from 'react-router-dom';
import { delete_scheme_by_id, get_schemes, get_consolidated_schemes, get_financial_year } from '../../helper/Api';
const Schemes = () => {
    const location = useLocation();
    const [filter, setFilter] = useState('own');
    const { authData } = useSelector((state) => state.authData);
    const [key, setKey] = useState('list');
    const searchStrRef = useRef('');
    const fyIdRef = useRef(2);
    const departmentIdRef = useRef('');
    const departmentId1Ref = useRef('');
    const tierRef = useRef('');
    const [schemesList, setSchemesList] = useState([]);
    const currentFY = useRef({});
    const dispatch = useDispatch();
    const [fyList, setFyList] = useState([]);
    const fetchSchemes = async () => {
        try {
            const schemes = await get_schemes({
                search_str: searchStrRef.current || '',
                fy_id: fyIdRef.current || '',
                department_id: departmentIdRef.current || '',
            });
            if (schemes.data.status) {
                if (schemes.data.schemes) {
                    let balance = 0;
                    currentFY.current = {
                        fy: schemes.data.current_fy,
                        total_provisional_budget: 0,
                        totalSanctionAmount: 0,
                        totalReleasedAmount: 0,
                        totalUnderApproval: 0,
                        totalPaidAmount: 0,
                        totalPendingAmount: 0,
                        totalBalanceAmount: 0
                    };
                    schemes.data.schemes.forEach((e) => {
                        balance += e.balance
                        currentFY.current.total_provisional_budget += e.total_provisional_budget;
                        currentFY.current.totalSanctionAmount += e.utilised_budget;
                        currentFY.current.totalReleasedAmount += e.total_budget;
                        currentFY.current.totalUnderApproval += e.pending_budget;
                        currentFY.current.totalPaidAmount += e.total_payment;
                        currentFY.current.totalPendingAmount += (e.utilised_budget - e.total_payment);
                        currentFY.current.totalBalanceAmount += e.balance;
                    });
                    setSchemesList(schemes.data.schemes);
                }
            } else {
                dispatch(GET_SCHEME_FAIL(schemes.data.message));
            }
        } catch (error) {
            dispatch(GET_SCHEME_FAIL('something went wrong'));
        }

    }
    const fetchSchemesConsolidate = async () => {
        try {
            let departmentId = '';
            console.log("conidtion",departmentId1Ref.current,departmentId1Ref.current);
            if (departmentIdRef.current !== "" && departmentId1Ref.current !== "") {
                // You can decide which value to send, for example, let's choose departmentIdRef.current
                departmentId = departmentId1Ref.current;
                console.log("if conidtion",departmentId1Ref.current);
            }
            else{
                console.log("else conidtion",departmentIdRef.current);
                departmentId = departmentIdRef.current;
            }
            const schemes = await get_consolidated_schemes({
                department_id: departmentId || 1,
                fy_id: fyIdRef.current || '',
                search_str: searchStrRef.current || '',
            });
            if (schemes.data.status) {
                schemes.data.schemes = schemes.data.result;
                if (schemes.data.schemes) {
                    let balance = 0;
                    currentFY.current = {
                        fy: schemes.data.current_fy,
                        total_provisional_budget: 0,
                        totalSanctionAmount: 0,
                        totalReleasedAmount: 0,
                        totalUnderApproval: 0,
                        totalPaidAmount: 0,
                        totalPendingAmount: 0,
                        totalBalanceAmount: 0
                    };
                    schemes.data.schemes.forEach((e) => {
                        balance += e.balance
                        currentFY.current.total_provisional_budget += e.total_provisional_budget;
                        currentFY.current.totalSanctionAmount += e.utilised_budget;
                        currentFY.current.totalReleasedAmount += e.total_budget;
                        currentFY.current.totalUnderApproval += e.pending_budget;
                        currentFY.current.totalPaidAmount += e.total_payment;
                        currentFY.current.totalPendingAmount += (e.utilised_budget - e.total_payment);
                        currentFY.current.totalBalanceAmount += e.balance;
                    });
                    setSchemesList(schemes.data.schemes);
                }
            } else {
                dispatch(GET_SCHEME_FAIL(schemes.data.message));
            }
        } catch (error) {
            dispatch(GET_SCHEME_FAIL('something went wrong'));
        }

    }
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const newReportType = queryParams.get('reportType') || 'own';  // default fallback
        setFilter(newReportType);
        fetchFinancialYears();
        if (newReportType == 'own') {
            fetchSchemes();
        }
        else {
            setSchemesList([])
        }
    }, [location.search]);

    const handleDateChange = (searchStr, fyId, tier, departmentId, departmentId1) => {
        // Update refs without triggering re-renders
        searchStrRef.current = searchStr;
        fyIdRef.current = fyId;
        tierRef.current = tier;
        departmentIdRef.current = departmentId;
        departmentId1Ref.current = departmentId1;
        if (filter == 'child') {
            fetchSchemes();
        }
        else if (filter == 'consolidate') {
            fetchSchemesConsolidate();
        }
    };

    // Fetch financial years
    const fetchFinancialYears = async () => {
        const fy = await get_financial_year();
        if (fy.data.status) {
            setFyList(fy.data.list);
        }
    };
    return (
        <div>
            <Tools></Tools>
            <ManageDisbursmentHeader type="Schemes" prefix={filter} />
            <SchemeFilter onDateChange={handleDateChange} filterType={filter} fyData={fyList} />
            <div className='scheme p-3'>
                <div className='scheme p-2'>
                    <div className='all_tabs'>
                        <Tabs
                            // defaultActiveKey="InvoiceList"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="list" title="Schemes List" className='p-2'>
                                <SchemesList listType={key} schemesList={schemesList} />
                            </Tab>
                            {authData.user.user_type != 'l1' ?
                                <Tab eventKey="actionList" title="Pending Action" className='p-2'>
                                    <SchemesList listType={key} schemesList={schemesList} />
                                </Tab> : ''}
                        </Tabs>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Schemes
