import React, {useEffect, useState} from 'react';
import {getRoyaltyPaymentList} from '../../../helper/Api';
import DataTable from 'react-data-table-component';
import {AiOutlineEye} from 'react-icons/ai';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';

const RoyaltyPaymentList = ({fromDate, toDate, searchStr}) => {
    const rowsPerPage = 10;
    const [data, setData] = useState([]);
    const { authData } = useSelector((state) => state.authData);

    const fetchChallans = async (fromDate, toDate, searchStr) => {
        try {
            const data = await getRoyaltyPaymentList({
                from_date: fromDate,
                to_date: toDate,
                searchStr,
                user_type: authData.user.user_type
            });
            if (data.data.status) {
                setData(data.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    useEffect(() => {
        fetchChallans(fromDate, toDate, searchStr);
    }, [fromDate, toDate, searchStr])


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Payment Order No',
            selector: (row) => row.payment_order_no,
            wrap: true,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Payment Order Date',
            selector: (row) => row.payment_order_date,
            wrap: true,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Authority Name',
            selector: (row) => <b>{row.name || ''}</b>,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Account No',
            selector: (row) => row.account_no,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'IFSC Code',
            selector: (row) => row.ifsc_code,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'Amount',
            selector: (row) => row.amount,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'L2 Approval',
            selector: (row) => Number(row.l2_approval) === 2 ? "Rejected" : Number(row.l2_approval) === 1 ? "Approved" : "Pending",
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'L3 Approval',
            selector: (row) => Number(row.l3_approval) === 2 ? "Rejected" : Number(row.l3_approval) === 1 ? "Approved" : "Pending",
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Action',
            selector: (row) => {
                return (
                    <>
                        <span className='pr-4'><Link to={`/view-royalty/${row.payment_order_no}`}><AiOutlineEye/></Link></span>
                    </>
                )
            },
            sortable: true,
            width: "150px",
            right: false
        }
    ];

    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
        </div>
    )
}

export default RoyaltyPaymentList
