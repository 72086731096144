import React, { useEffect, useRef, useState } from 'react'
import Tools from '../../components/Tools/Tools';
import ManageDisbursment from '../../components/Disbursment/ManageDisbursment';
import ManageDisbursmentHeader from '../../components/Disbursment/ManageDisbursmentHeader';
import { Tab, Tabs } from 'react-bootstrap';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import VenderContractServiceFilter from '../Filter/vendorContractServiceFilter';
import { get_disbursment_invoices } from '../../helper/Api';
import {
    GET_D_INVOICES_START,
    GET_D_INVOICES_SUCCESS,
    GET_D_INVOICES_FAIL
} from '../../RTK/Slices/DisbursmentInvoiceSlice';
import { useDispatch, useSelector } from 'react-redux';
const InvoiceList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchStrRef = useRef('');
    const tierRef = useRef('');
    const departmentIdRef = useRef('');
    const voucherDateRef = useRef('voucher_date');
    const selectDateRef = useRef('');
    const departmentRef = useRef([]);
    const searchParams = new URLSearchParams(location.search);
    const list = searchParams.get('list');
    const [heading, setHeading] = useState('Invoice-Service');
    const [filter, setFilter] = useState('own');
    const [data, setData] = useState([]);
    const handleDateChange = (searchStr,tier,departmentId,voucherDate,selectDate) => {
        searchStrRef.current = searchStr;
        tierRef.current = tier;
        departmentRef.current = departmentId;
        voucherDateRef.current= voucherDate;
        selectDateRef.current=selectDate;
        console.log("searchStr",searchStr,"tier",tier,"voucherDate",voucherDate,"departmentId",departmentId,"selectDate",selectDate);
        fetchInvoices();
    };

    const handleTabSelect = (key) => {
        if (key == 'paymentStatus') {
            setHeading("Invoice Payment");
            navigate('/fund-invoice-list?pending=true');
        } else if (key == 'InvoiceList') {
            setHeading("Invoice Approve");
            navigate('/fund-invoice-list?pending=false');
        } else if (key == 'Invoice-Service') {
            navigate('/invoices?list=true')
        }
    };
    const fetchInvoices = async () => {
        dispatch(GET_D_INVOICES_START());
        try {
            const invoices_response = await get_disbursment_invoices({
                dateColumn: voucherDateRef.current,
                search_str: searchStrRef.current,
                department_id: departmentIdRef.current,
                from_date:selectDateRef.current,
            });
            if (invoices_response.data.status) {
                dispatch(GET_D_INVOICES_SUCCESS(invoices_response.data.list))
                setData(invoices_response.data.list)
            } else {
                dispatch(GET_D_INVOICES_FAIL(invoices_response.data.message))
            }
        } catch (error) {
            dispatch(GET_D_INVOICES_FAIL('something went wrong'))
        }
    }
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const newReportType = queryParams.get('reportType') || 'own';  // default fallback
        setFilter(newReportType);
        if(newReportType=='own')
        {
            fetchInvoices();
        }
    }, [location.search]);

    return (
        <div>
            <Tools />
            {/* heading={heading} */}
            <ManageDisbursmentHeader onDateChange={handleDateChange} type="Disbursments" />
            <VenderContractServiceFilter filterType={filter} onDateChange={handleDateChange}/>
            <div className='scheme p-2'>
                {
                    list && <div className='all_tabs'>
                        <Tabs
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={'Invoice-Service'}
                            onSelect={handleTabSelect}
                        >
                            <Tab eventKey="InvoiceList" title="Invoice Approval" className='p-2'></Tab>
                            <Tab eventKey="paymentStatus" title="Invoice Payment" className='p-2'></Tab>
                            <Tab eventKey="Invoice-Service" title="Vendor/Contract/Invoice/Service Provider Payments" className='p-2'></Tab>
                            <Tab eventKey="Penalty" title="Penalties Approval" className='p-2'></Tab>
                            <Tab eventKey="List-Penalty" title="Penalty Payments" className='p-2'></Tab>
                            <Tab eventKey="Withheld" title="Withheld Amount Payment" className='p-2'></Tab>
                            <Tab eventKey="List-Withheld" title="Withheld Amount Payments" className='p-2'></Tab>
                        </Tabs>
                    </div>
                }

                <ManageDisbursment tableData={data}/>
            </div>
        </div>
    )
}


export default InvoiceList
