import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import {AddCommasToAmount} from "../../../helper/Utils";
import DataTable from "react-data-table-component";
import Tools from "../../../components/Tools/Tools";
import {Form} from "react-bootstrap";
import {ADD_CHALLAN_FAIL, ADD_CHALLAN_START} from "../../../RTK/Slices/ChallanSlice";
import { getAuthorities, getAuthoritieAccounts, createLabourCessChallan } from "../../../helper/Api";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";


const AddLabourCess = () => {
    let {state} = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data] = useState(state && state?.data ? state.data : []);
    const [authorityAccounts, setAuthorityAccounts] = useState([]);
    const [authorities, setAuthorities] = useState([]);
    const [formData, setFormData] = useState({
        id: '',
        invoices: [],
        payment_order_no: '',
        amount: '',
        payment_order_date: '',
        doc_url: '',
        attachments: [],
        authority_id: '',
        authority_account_id: '',
    });

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Voucher No',
            selector: (row) => <b>{row.voucher_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Voucher\n' +
                'Date',
            selector: (row) => <b>{row.voucher_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'Transaction\n' +
                'Date',
            selector: (row) => <b>{row.amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Invoice No',
            selector: (row) => <b>{row.invoice_no}</b>,
            wrap: true,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Invoice Date',
            selector: (row) => <b>{row.invoice_date}</b>,
            wrap: true,
            sortable: true,
            width: "200px",

        },
        {
            name: 'Beneficiary\n' +
                'Name',
            selector: (row) => <b>{row.company_name || ''}</b>,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Approval\n' +
                'Type',
            selector: (row) =>
                <b>{row.payment_type === '1' ? "FULL PAYMENT" : row.payment_type === '2' ? 'PART PAYMENT' : ''}</b>,
            sortable: true,
            width: "250px",
            wrap: true,
        },
        {
            name: 'Taxable\n' +
                'Amount',
            selector: (row) => <b>{row.taxable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'INVOICE\n' +
                'AMOUNT',
            selector: (row) => <b>{row.payable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'INVOICE\n' +
                'Amount\n' +
                'Sanctioned',
            selector: (row) => <b>{row.sanction_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'Labour Cess Amount',
            selector: (row) => <b>{row?.labour_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
    ];

    const calculateTotalAmount = (propertyName) => {
        let total = 0;
        data.forEach((row) => {
            const depositAmount = Number(parseFloat(row[propertyName]));
            if (!isNaN(depositAmount)) {
                total += depositAmount;
            }
        });
        return total.toFixed(2);
    };

    const totalInvoiceAmount = calculateTotalAmount('labour_amount');

    const footerRow = {
        isFooter: true,
        selectableRowsSingle: false,
        invoice_no: <b>Total</b>,
        nps: <b>{AddCommasToAmount(totalInvoiceAmount)}</b>,
    };

    const updatedData = [...data, footerRow];

    useEffect(() => {
        fetchAuthorities();
    }, []);

    const handleAttachmentChange = (event) => {
        const files = event.target.files;
        setFormData({
            ...formData,
            attachment: files
        });
    };

    const fetchAuthorityAccounts = async (id) => {
        try {
            const accounts = await getAuthoritieAccounts(id);
            if (accounts.data.status) {
                setAuthorityAccounts(accounts.data.result)
            }
        } catch (e) {

        }
    }

    const handleChallanFormData = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'authority_id') {
            await fetchAuthorityAccounts(value)
        }
        setFormData({...formData, [name]: value})
    }

    const fetchAuthorities = async () => {
        try {
            const accounts = await getAuthorities();
            if (accounts.data.status) {
                setAuthorities(accounts.data.result)
            }
        } catch (e) {

        }


    }

    const handleSubmit = async (e) => {
        try {
            const invoices = [];

            data.forEach(el => {
                invoices.push(el.id);
            });
            const post_data = new FormData();
            for (let i = 0; i < formData.attachment.length; i++) {
                post_data.append('attachment[]', formData.attachment[i]);
            }
            post_data.append('invoices', invoices.join(','));
            post_data.append('payment_order_date', formData.payment_order_date);
            post_data.append('payment_order_no', formData.payment_order_no);
            post_data.append('amount', totalInvoiceAmount);
            post_data.append('authority_id', formData.authority_id);
            post_data.append('authority_account_id', formData.authority_account_id);
            dispatch(ADD_CHALLAN_START());
            const response = await createLabourCessChallan(post_data);
            let addChallanResponseData = response.data;
            if (addChallanResponseData.status) {
                // dispatch(ADD_CHALLAN_SUCCESS(addChallanResponseData.list[0]));
                navigate('/labour');
            } else {
                toast.error(addChallanResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(ADD_CHALLAN_FAIL(addChallanResponseData.message));
            }
        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating challan'));

        }
    }

    return (
        <div>
            <Tools/>
            <div className='addNewScheme'>
                <div className="add_new_user">
                    <h4>Add New Payment</h4>
                    <div>
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add Payment</button>
                    </div>
                </div>
            </div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={updatedData}
                fixedHeader
                fixedHeaderScrollHeight="600px"
            />


            <div>
                <div className="p-3">
                    <div className="row">
                        <Form>
                            <div className="col-md-12">

                                <div className="card p-3 mt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputScheme"
                                                               className="col-sm-4 col-form-label">Payment Order No
                                                            <span className="text-danger">*</span> :</label>
                                                        <div className="col-sm-8">
                                                            <input type="text" className="form-control" id="challan_no"
                                                                   name='payment_order_no'
                                                                   required
                                                                   value={formData.challan_no}
                                                                   onChange={handleChallanFormData}
                                                                   placeholder="Enter Challan No"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputFinancials"
                                                               className="col-sm-4 col-form-label">
                                                            Payment Order Date
                                                            <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8 ">
                                                            <Form.Control
                                                                type="date"
                                                                name="payment_order_date"
                                                                value={formData.challan_date}
                                                                onChange={handleChallanFormData}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="head_of_account_id"
                                                               className="col-sm-4 col-form-label">
                                                            Authority
                                                            <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control "
                                                                    id="type" name='authority_id'
                                                                    required
                                                                    value={formData.authority_id}
                                                                    onChange={handleChallanFormData}>
                                                                <option value="">Select Authority</option>
                                                                {
                                                                    authorities.map((st) => {
                                                                        return (
                                                                            <option value={st.id}
                                                                                    key={st.id}>{st.name}</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputSchemee"
                                                               className="col-sm-4 col-form-label">Authority A/c<span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control "
                                                                    id="type" name='authority_account_id'
                                                                    required
                                                                    value={formData.authority_account_id}
                                                                    onChange={handleChallanFormData}>
                                                                <option value="">Select Authority</option>
                                                                {
                                                                    authorityAccounts.map((st) => {
                                                                        return (
                                                                            <option value={st.id}
                                                                                    key={st.id}>{st.bank_name} [{st.account_no}]</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputSchemee"
                                                               className="col-sm-4 col-form-label">Challan Value<span
                                                            className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <input type="text" className="form-control"
                                                                   name='bsr_code'
                                                                   required
                                                                   readOnly
                                                                   placeholder="Enter Challan Value"
                                                                   onChange={handleChallanFormData}
                                                                   value={totalInvoiceAmount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 p-2">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="input-group">
                                                                <label className="input-group-btn my-0 mt-2 mr-2">
                                                                    Upload Challan<span className='text-danger'>*</span>:
                                                                </label>
                                                            </div>
                                                            {
                                                                !formData?.attachments?.length ? (
                                                                    <label className='p-1'>
                                                                        <input id="" type="file"
                                                                               className='form-control'
                                                                               onChange={handleAttachmentChange}
                                                                               name='attachment'
                                                                               accept=".pdf,.jpeg,.jpg,.png"
                                                                               multiple/>
                                                                    </label>
                                                                ) : (
                                                                    formData.attachments.map((att, i) => {
                                                                        return (
                                                                            <a className="mt-2 mx-2" href={att.file_url}
                                                                               target='_blank' rel="noopener noreferrer">Attachment {i + 1}</a>);
                                                                    })
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddLabourCess
