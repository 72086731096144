import React from 'react'
import Tools from "../../components/Tools/Tools"
import Profile from '../../components/Users/Profile'

const ProfilePage = () => {
  return (
    <div>
      <Tools></Tools>
      <Profile></Profile>
    </div>
  )
}

export default ProfilePage