import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './BudgetAnalysis.css'
import AddInterestModal from '../Modal/AddInterestModal';
import { 
  add_account_balance_interest,
  get_financial_year, 
  update_account_balance_interest,
  get_departments
} from '../../helper/Api';
import { toast } from 'react-toastify';
import { Col, Container, Form, Row } from 'react-bootstrap'
import moment from 'moment';

const BudgetAnalysis = ({handleYearDropdown, isShowPopup, previewBankAccountData, handlePopUpState, handleFilter}) => {
  const {authData}=useSelector((state)=>state.authData);
  const [showPreview,setShowPreview]=useState(isShowPopup);
  const [formData,setFormData]=useState({
    "id": "",
    "account_name": "",
    "bank_name": "",
    "branch_name": "",
    "ifsc_code": "",
    "ifsc_code_bank": "",
    "account_type": "",
    "account_no":"",
    "account_balance":"",
    "balance_date":"",
    "interest":"",
    "quarter":"",
    "interest_date":"",
    "email":"",
    "mobile":"",
    "l2_approval": "",
    "l3_approval": "",
    "l2_remark": "",
    "l3_remark": "",
    'added_by':authData.user.id,
    "department_id":authData.user.department_id
  });
  const [fy_list, set_fy_list] = useState([]);
  const [tier, setTier] = useState('');
  const [department, setDepartment] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const interest_modal=(e)=>{
    e.preventDefault();
    setFormData({
      "account_name": "",
      "bank_name": "",
      "branch_name": "",
      "ifsc_code": "",
      "account_type": "",
      "account_no":"",
      "account_balance":"",
      "balance_date":"",
      "ifsc_code_bank": "",
      "interest":"",
      "interest_date":"",
      "email":"",
      "mobile":"",
      'added_by':authData.user.id,
      "department_id":authData.user.department_id
    });
    setShowPreview(true);
  }

  const fetchDepartments = async (level) => {
          try {
              const departments = await get_departments({ department_level: level, parent_department_id:  authData.user.department_id});
              if (departments.data.status) {
                  setDepartment(departments.data.result);
              }
          } catch (error) {
              console.log("get_departments error");
          }
      }

  const get_balance_interest=async()=>{
    try {
      const fy = await get_financial_year();
      if (fy.data.status) {
        set_fy_list(fy.data.list)
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
    get_balance_interest();
    setShowPreview(isShowPopup);
    setFormData(previewBankAccountData)
  }, [isShowPopup])

  const handleSubmit=async(e)=>{
    e.preventDefault();
    // console.log(authData.user)
    try {
      let res;
      if(isShowPopup) {
        formData.l2_approval = null;
        formData.l3_approval = null;
         const {data} = await update_account_balance_interest(formData, previewBankAccountData.id);
         handlePopUpState(false);
        res = data;
      } else {
        const {data} =await add_account_balance_interest(formData);
        res = data;
      }
      // console.log('res', res);
      if(res.status){
        setShowPreview(false);
        toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER
        });
        get_balance_interest();
      }else{
        toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.log(error)
      toast.error("System Error. Try Later!", {
          position: toast.POSITION.TOP_CENTER
      });
    }
  }


  const clearFilter = () => {
    setTier('');
    setDepartmentId('');
  };
  const handleInput = async (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'tier') {
        await fetchDepartments(value);
        setTier(value);
    } else if (name === 'department_id') {
        setDepartmentId(value);
    }
  }

  return (
    <>
    <div className="budget_analysis">
      <AddInterestModal showPreview={showPreview} setShowPreview={setShowPreview} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} handlePopUp={handlePopUpState}></AddInterestModal>
        <h1>Dashboard - Financial Year 2024-2025 <span style={{
          fontSize: '16px', fontStyle: 'italic', color: '#da4d42'
        }}>(All figures in indian rupees)</span></h1>
        <div className="budget_date_wrapper">
        {authData.user.user_type==='l1'?(
          <button type="button" className="btn btn-primary" style={{float: 'right', marginLeft: '10px'}} onClick={interest_modal}>Add Scheme Bank Account</button>
        ):""}
          <select 
          className="form-control" 
          id="financial_year"
          name='financial_year_id'
          style={{
            width: '250px',
            float: 'right'
          }}
          onChange={handleYearDropdown}
          >
            <option value="">---select year---</option>
            {
              fy_list.map((fy) => {
                  return (
                      <option 
                      value={fy.id}
                      key={fy.id}
                      selected={fy.start_year === moment().format('YYYY') ? true : false}
                      >{fy.year}</option>
                  );
              })
            }
        </select>
        
        </div>

        
    </div>
    {/* filters */}
    <div className="add_new_user mt-2">
          <Container fluid>
            <Row>
              <Col sm={12}>
                  <h5>Filters</h5>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="">
                    <select
                        className="form-control"
                        name="tier"
                        onChange={handleInput}
                    >
                        <option value="">Select Department Tier</option>
                        {
                            authData.user.department_details.department_level === 'T1' && <option value="T1" selected={tier === 'T1' ? true : false}>T1</option>
                        }

                        {
                            (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2') && <option value="T2" selected={tier === 'T2' ? true : false}>T2</option>
                        }

                        {
                            (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2' || authData.user.department_details.department_level === 'T3') && <option value="T3" selected={tier === 'T3' ? true : false}>T3</option>
                        }

                        {
                            (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2' || authData.user.department_details.department_level === 'T3' || authData.user.department_details.department_level === 'T4') && <option value="T4" selected={tier === 'T4' ? true : false}>T4</option>
                        }
                    </select>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="">
                  <select
                      className="form-control"
                      name="department_id"
                      onChange={handleInput}
                  >
                      <option value="">Select Department</option>
                      {department.map(option => (
                          <option 
                          key={option.id} 
                          value={option.id}
                          selected={departmentId === option.id ? true : false}
                          >{option.name}</option>
                      ))}
                  </select>
                </Form.Group>
              </Col>
              <Col sm={12}>
                  <div className="d-flex mt-3">
                      <div className="text-start mx-2">
                          <button type="button" className="btn btn-outline-primary"
                              onClick={clearFilter}>Clear
                          </button>
                      </div>
                      <div className="text-start">
                          <button type="button" className="btn btn-primary"
                              onClick={() => handleFilter(departmentId, tier)}>Filter
                          </button>
                      </div>
                  </div>
              </Col>
            </Row>
          </Container>
        </div>
    </>
  )
}

export default BudgetAnalysis