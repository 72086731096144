import React from 'react'
import Tools from "../../components/Tools/Tools"
import ViewUser from '../../components/Users/ViewUser'

const ViewUserPage = () => {
  return (
    <div>
      <Tools></Tools>
      <ViewUser></ViewUser>
    </div>
  )
}

export default ViewUserPage