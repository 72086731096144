import React, { useEffect, useState } from 'react'
import './AddSchemeForm.css';
import { Button, Form } from 'react-bootstrap';
import {
    get_financial_year,
    get_scheme_type,
    add_schemes,
    get_schemes_details_by_id,
    update_scheme_by_id,
    getSchemeAccountBalances,
    getActivity, getSubActivity, get_schemes_flags
} from '../../../helper/Api';
import { ADD_SCHEME_START, ADD_SCHEME_FAIL, ADD_SCHEME_SUCCESS } from '../../../RTK/Slices/SchemeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaUpload } from 'react-icons/fa'
import { AddCommasToAmount } from '../../../helper/Utils';
import { DeleteForever } from '@mui/icons-material';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
const EditSchemeForm = () => {

    const [fy_list, set_fy_list] = useState([]);
    const [st_list, set_st_list] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [activity, setActivity] = useState([]);
    const [subActivity, setSubActivity] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch();
    const { authData } = useSelector((state) => state.authData);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        type_id: '',
        grant_code: '',
        department: '',
        financial_year_id: '',
        // carry_forwarded: '',
        attachments: [],
        sub_head_list: [
            { name: '', code: '', financial_year: '', budget: '', provisional_budget: '' },
        ],
        account_id: '',
        // bank_details: {
        //     account_name: '',
        //     bank_name: '',
        //     branch_name: '',
        //     account_no: '',
        //     account_type: '',
        //     ifsc_code: ''
        // },
        monitoring_level: '',
        change_reimbursement_payment_flow: "",
        reimbursement_payment_levels: [],
        added_by: authData && authData.status ? authData.user.id : null
    });
    const [schemeFlag, setSchemeFlag] = useState([]);
    const fetch_list = async () => {
        const fy = await get_financial_year();
        const st = await get_scheme_type();
        const accounts = await getSchemeAccountBalances();
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }
        if (st.data.status) {
            set_st_list(st.data.list)
        }
        if (accounts.data.status) {
            setAccountList(accounts.data.result);
        }
    }
    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setActivity(resp.data.result);
            }
        } catch (error) {
            setActivity([]);
        }
    }
    const fetchSubActivity = async () => {
        try {
            const resp = await getSubActivity();
            if (resp.data.status) {
                setSubActivity(resp.data.result);
            }
        } catch (error) {
            setSubActivity([]);
        }
    }
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    const currentDate = new Date().toISOString().split('T')[0];

    const get_scheme_details = async () => {
        try {
            const { data } = await get_schemes_details_by_id(id);
            // console.log(data)
            if (data.schemes.length > 0) {
                let schemeData = data.schemes[0];
                const transformedData = {
                    ...schemeData,
                    sub_head_list: schemeData.sub_head_list.map(subHead => ({
                        id: subHead.id,
                        name: subHead.name,
                        code: subHead.code,
                        financial_year: subHead.financial_year,
                        budget: subHead.budget,
                        provisional_budget: subHead.provisional_budget,
                        activity: subHead.default_budget.activities.map(activity => ({
                            id: activity.id,
                            activity_id: activity.subhead_activity_id,
                            subactivity_id: activity.subhead_subactivity_id,
                            provisional_budget: activity.provisional_budget,
                            budget: activity.budget,
                        }))
                    })),
                };
                setFormData(transformedData);
            } else {
                setFormData({
                    name: '',
                    code: '',
                    type: '',
                    grant_code: '',
                    department: '',
                    financial_year: '',
                    attachments: [],
                    sub_head_list: [],
                    bank_details: {
                        account_name: '',
                        bank_name: '',
                        branch_name: '',
                        account_no: '',
                        account_type: '',
                        ifsc_code: ''
                    },

                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchSchemeFlag = async () => {
        try {
            const resp = await get_schemes_flags();
            if (resp.data.status) {
                setSchemeFlag(resp.data.result);
            }
        } catch (error) {
            setSchemeFlag([]);
        }
    }
    useEffect(() => {
        fetchSchemeFlag();
        fetchActivity();
        fetchSubActivity();
        fetch_list();
        get_scheme_details();
    }, [id])

    const handleAttachmentChange = (event) => {
        const files = Array.from(event.target.files);
        // console.log(files);
        setFormData({
            ...formData,
            attachments: files
        });
        // console.log(formData)
    };
    const handleSchemFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name == 'reimbursement_payment_levels') {
            let updatedLevels = Array.isArray(formData.reimbursement_payment_levels)
            ? [...formData.reimbursement_payment_levels]
            : [];
            if (e.target.checked) {
                if (!updatedLevels.includes(value)) {
                    updatedLevels.push(value);
                }
            } else {
                updatedLevels = updatedLevels.filter(level => level !== value);
            }
            setFormData({
                ...formData,
                reimbursement_payment_levels: updatedLevels,
            });
        }
        else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubHeadChange = (index, field, value) => {
        const newSubHeads = [...formData.sub_head_list];
        newSubHeads[index][field] = value;
        setFormData({
            ...formData,
            sub_head_list: newSubHeads
        });
    };

    const total_scheme_budget = () => {
        const subheads = formData.sub_head_list;
        let total_budget = 0;
        subheads.map((sub) => {

            if (sub.budget) {
                total_budget = total_budget + parseFloat(sub.budget)
            }
            return {}
        })
        return AddCommasToAmount(total_budget.toFixed(2));
    }


    const handleBankChange = (field, value) => {
        const newBankData = {
            ...formData.bank_details,
            [field]: value
        };
        setFormData({
            ...formData,
            bank_details: newBankData
        });
    };

    const handleAddSubHead = () => {
        const newSubHeads = [...formData.sub_head_list];
        newSubHeads.push({ name: '', code: '', financial_year: '', budget: '' });
        setFormData({
            ...formData,
            sub_head_list: newSubHeads
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const post_data = new FormData();
            for (let i = 0; i < formData.attachments.length; i++) {
                post_data.append('attachment[]', formData.attachments[i]);
            }
            post_data.append('name', formData.name);
            post_data.append('code', formData.code);
            post_data.append('type', formData.type_id);
            post_data.append('grant_code', formData.grant_code);
            post_data.append('department', formData.department);
            post_data.append('financial_year', formData.financial_year_id);
            // post_data.append('carry_forwarded', formData.carry_forwarded);
            post_data.append('sub_heads', JSON.stringify(formData.sub_head_list));
            post_data.append('bank', JSON.stringify(formData.bank_details));
            post_data.append('added_by', authData.user.id);

            const add_scheme_response = await update_scheme_by_id(post_data, id);

            let addSchemeResponseData = add_scheme_response.data;
            if (addSchemeResponseData.status) {
                toast.success(addSchemeResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });

                navigate('/schemes');
            } else {
                toast.error(addSchemeResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });

            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteSubhead = (index) => {
        const updatedSubHeads = [...formData.sub_head_list];
        updatedSubHeads.splice(index, 1);
        setFormData({
            ...formData,
            sub_head_list: updatedSubHeads,
        });
    };
    const handleActivityChange = (subHeadIndex, activityIndex, e, activityId) => {
        const { name, value } = e.target;
        const SubHeads = [...formData.sub_head_list];
        if (activityId) {
            const activityValid = SubHeads[subHeadIndex].activity.find(item => item.subactivity_id === value && item.activity_id === activityId);
            if (activityValid) {
                return alert('Sub-activity already selected');
            }
        }
        setFormData(prevFormData => {
            const updatedSubHeads = prevFormData.sub_head_list.map((subHead, sIdx) => {
                if (sIdx === subHeadIndex) {
                    const updatedActivities = subHead.activity.map((activity, aIdx) => {
                        if (aIdx === activityIndex) {
                            const updatedActivity = {
                                ...activity,
                                [name]: value
                            };
                            if (name === 'activity_id') {
                                updatedActivity.subactivity_id = '';
                            }
                            return updatedActivity;
                        }
                        return activity;
                    });
                    return {
                        ...subHead,
                        activity: updatedActivities
                    };
                }
                return subHead;
            });
            return {
                ...prevFormData,
                sub_head_list: updatedSubHeads
            };
        });
        console.log(formData.sub_head_list);
    };
    const addNewActivity = (index) => {
        const newActivity = { activity_id: '', subactivity_id: '', provisional_budget: 0, budget: 0 };
        setFormData(prevFormData => {
            const updatedSubHeads = prevFormData.sub_head_list.map((subHead, idx) => {
                if (idx === index && subHead.activity) {
                    return {
                        ...subHead,
                        activity: [...subHead.activity, newActivity]
                    };
                }
                return subHead;
            });
            return {
                ...prevFormData,
                sub_head_list: updatedSubHeads
            };
        });
    };
    const deleteNewActivity = (index, activityIndex) => {
        if (formData.sub_heads[index].activity.length > 1) {
            setFormData(prevFormData => {
                const updatedSubHeads = prevFormData.sub_head_list.map((subHead, idx) => {
                    if (idx === index && subHead.activity) {
                        const updatedActivities = [...subHead.activity];
                        updatedActivities.splice(activityIndex, 1);
                        return {
                            ...subHead,
                            activity: updatedActivities
                        };
                    }
                    return subHead;
                });
                return {
                    ...prevFormData,
                    sub_head_list: updatedSubHeads
                };
            });
        }
    };
    const handleSubheadBudgetFormData = (index, activityIndex, e) => {
        const { name, value } = e.target;
        let totalProvisionalBudget = 0;
        let totalBudget = 0;
        formData.sub_head_list[index].activity.forEach(item => {
            totalProvisionalBudget += parseFloat(item.provisional_budget || 0);
            totalBudget += parseFloat(item.budget || 0);
        });
        if (name === 'provisional_budget') {
            totalProvisionalBudget = totalProvisionalBudget + Number(value);
            if (totalProvisionalBudget >= formData.sub_head_list[index][name]) {
                return alert('Total provisional budget exceeds the allowed amount.');
            }
        }
        if (name === 'budget') {
            totalBudget = totalBudget + Number(value);
            if (totalBudget >= formData.sub_head_list[index][name]) {
                return alert('Total budget exceeds the allowed amount.');
            }
        }
        setFormData(prevFormData => {
            const updatedSubHeads = prevFormData.sub_head_list.map((subHead, sIdx) => {
                if (sIdx === index) {
                    const updatedActivities = subHead.activity.map((activity, aIdx) => {
                        if (aIdx === activityIndex) {
                            const updatedActivity = {
                                ...activity,
                                [name]: value
                            };
                            return updatedActivity;
                        }
                        return activity;
                    });
                    return {
                        ...subHead,
                        activity: updatedActivities
                    };
                }
                return subHead;
            });
            return {
                ...prevFormData,
                sub_head_list: updatedSubHeads
            };
        });
        console.log(formData.sub_head_list);
    };
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>Update Scheme</h4>
                                <div>
                                    <Link to="/schemes">
                                        <button type="button" className="btn btn-light">CANCEL</button>
                                    </Link>&nbsp;
                                    <button type="submit" className="btn btn-primary">UPDATE</button>
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputDepartment"
                                                        className="col-sm-4 col-form-label">Department
                                                        Name
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text"
                                                            className="form-control" id="department"
                                                            name='department'
                                                            maxLength={50}
                                                            required
                                                            value={formData.department}
                                                            onChange={handleSchemFormData}
                                                            placeholder="Enter Department Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="type_id" className="col-sm-4 col-form-label">Type of
                                                        Scheme
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control "
                                                            id="type_id" name='type_id'

                                                            required
                                                            onChange={handleSchemFormData}>
                                                            <option value="">Select Scheme Type</option>
                                                            {
                                                                st_list.map((st) => {
                                                                    return (
                                                                        <option
                                                                            selected={st.id === formData.type_id}
                                                                            value={st.id}
                                                                            key={st.id}>{st.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputScheme" className="col-sm-4 col-form-label">Scheme
                                                        Name
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" className="form-control" id="name"
                                                            name='name'
                                                            required
                                                            maxLength={200}
                                                            value={formData.name}
                                                            onChange={handleSchemFormData}
                                                            placeholder="Enter Scheme Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputGrant" className="col-sm-4 col-form-label">Grant
                                                        Code
                                                        <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input type="number" className="form-control" id="grant_code"
                                                            required
                                                            value={formData.grant_code}
                                                            name='grant_code'
                                                            onChange={handleSchemFormData}
                                                            placeholder="Enter Grant Code" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="financial_year_id"
                                                        className="col-sm-4 col-form-label">
                                                        Financial Year
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control " id="financial_year_id"
                                                            name='financial_year_id'
                                                            onChange={handleSchemFormData}
                                                            required
                                                        >
                                                            <option value="">---select year---</option>
                                                            {
                                                                fy_list.map((fy) => {
                                                                    return (
                                                                        <option value={fy.id} key={fy.id}
                                                                            selected={fy.id === formData.financial_year_id}
                                                                        >{fy.year}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputSchemee" className="col-sm-4 col-form-label">Scheme
                                                        Code <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="number" className="form-control" id="code"
                                                            name='code'
                                                            required
                                                            value={formData.code}
                                                            placeholder="Enter Scheme Code"
                                                            onChange={handleSchemFormData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 p-2">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="input-group">
                                                            <label className="input-group-btn my-0">
                                                                Upload Scheme Document

                                                            </label>
                                                            <label className='p-1'><input id="" type="file"
                                                                className='form-control'
                                                                onChange={handleAttachmentChange}
                                                                name='attachment'
                                                                accept=".pdf,.jpeg,.jpg,.png"
                                                                multiple /></label>

                                                        </div>
                                                        {
                                                            formData?.attachments.length != 0 && formData.attachments.map((att, i) => {
                                                                return (<a href={att.file_url}
                                                                    target='_blank'>Attachment {i + 1}</a>);
                                                            })
                                                        }
                                                    </div>
                                                    {/* <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label htmlFor="inputType"
                                                                   className="col-sm-4 col-form-label">Carry forward
                                                                <span className="text-danger">*</span> :</label>
                                                            <div className="col-sm-8">
                                                                <select className="form-control "
                                                                        id="carry_forwarded" name='carry_forwarded'
                                                                        required
                                                                        value={formData.carry_forwarded}
                                                                        onChange={handleSchemFormData}>
                                                                    <option value="">---Select---</option>
                                                                    <option value="1">Enable</option>
                                                                    <option value="2">Disable</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label for="inputType" className="col-sm-4 col-form-label">Bank Account
                                                                <span className="text-danger">*</span> :</label>
                                                            <div className="col-sm-8">
                                                                <select className="form-control " name='account_id'
                                                                    required
                                                                    onChange={handleSchemFormData}>
                                                                    <option value="">---Select---</option>
                                                                    {
                                                                        accountList.map(e => {
                                                                            return <option value={e.id}
                                                                                selected={formData.account_id == e.id ? true : false}
                                                                            >{e.account_name} [A/c No: {e.account_no}]</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {formData.is_activity_active == '1' && <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label for="inputType" className="col-sm-4 col-form-label">Monitor/Control Level
                                                                <span className="text-danger">*</span>:</label>
                                                            <div className="col-sm-8">
                                                                <select className="form-control"
                                                                    id="activity_wise" name='monitoring_level'
                                                                    required
                                                                    onChange={handleSchemFormData}
                                                                    value={formData.monitoring_level}>
                                                                    <option value="">---Select---</option>
                                                                    {schemeFlag.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label for="inputType" className="col-sm-4 col-form-label">Change Payment Flow
                                                                <span className="text-danger">*</span>:</label>
                                                            <div className="col-sm-8">
                                                                <select className="form-control"
                                                                    id="activity_wise" name='change_reimbursement_payment_flow'
                                                                    required
                                                                    value={formData.change_reimbursement_payment_flow}
                                                                    onChange={handleSchemFormData}>
                                                                    <option value="">---Select---</option>
                                                                    <option value="1">YES</option>
                                                                    <option value="0">NO</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label htmlFor="inputType" className="col-sm-4 col-form-label">
                                                                Payment Levels <span className="text-danger">*</span> :
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="T1"
                                                                        name="reimbursement_payment_levels"
                                                                        value="T1"
                                                                        checked={Array.isArray(formData?.reimbursement_payment_levels) && formData?.reimbursement_payment_levels.includes('T1')}
                                                                        onChange={handleSchemFormData}
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label" htmlFor="T1">
                                                                        T1
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="T2"
                                                                        name="reimbursement_payment_levels"
                                                                        value="T2"
                                                                        checked={Array.isArray(formData?.reimbursement_payment_levels) && formData?.reimbursement_payment_levels.includes('T2')}
                                                                        onChange={handleSchemFormData}
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label" htmlFor="T2">
                                                                        T2
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="T3"
                                                                        name="reimbursement_payment_levels"
                                                                        value="T3"
                                                                        checked={Array.isArray(formData?.reimbursement_payment_levels) && formData?.reimbursement_payment_levels.includes('T3')}
                                                                        onChange={handleSchemFormData}
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label" htmlFor="T3">
                                                                        T3
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 p-2">
                                                <div className="border mt-3">
                                                    <div className="p-1">
                                                        <p><span>Total Scheme Budget </span>: <span
                                                            className='scheme_budget'>{total_scheme_budget()}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData.sub_head_list.map((subHead, i) => {
                                                    return (
                                                        <>
                                                            {subHead.id > 0 ? <input type="hidden" name="subhead_id"
                                                                value={subHead.id} /> : ""}

                                                            <div className='container-fluid'>
                                                                <div className='row eachsubheadBlock'>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label htmlFor="inputSub"
                                                                                className="col-sm-4 col-form-label">Sub
                                                                                Head Name {i + 1}
                                                                                <span className="text-danger">*</span> :
                                                                            </label>
                                                                            <div className="col-sm-8">
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    id="name"
                                                                                    maxLength={100}
                                                                                    value={subHead.name}
                                                                                    required
                                                                                    onChange={(e) => handleSubHeadChange(i, 'name', e.target.value)}
                                                                                    placeholder="Enter Sub Head Name" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label htmlFor="inputFinancials"
                                                                                className="col-sm-4 col-form-label">
                                                                                Budget Date {i + 1}
                                                                                <span className="text-danger">*</span> :</label>
                                                                            <div className="col-sm-8 ">
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    name="budget_date"
                                                                                    max={currentDate}
                                                                                    value={subHead.budget_date}
                                                                                    onChange={(e) => handleSubHeadChange(i, 'budget_date', e.target.value)}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label htmlFor="inputHead"
                                                                                className="col-sm-4 col-form-label">Sub
                                                                                Head Code {i + 1}
                                                                                <span className="text-danger">*</span> :</label>
                                                                            <div className="col-sm-8">
                                                                                <input type="number"
                                                                                    required
                                                                                    className="form-control"
                                                                                    id="code"
                                                                                    value={subHead.code}
                                                                                    onChange={(e) => handleSubHeadChange(i, 'code', e.target.value)}
                                                                                    placeholder="Enter Sub Head Code" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label htmlFor="inputBudget"
                                                                                className="col-sm-4 col-form-label">Budget {i + 1}
                                                                                :</label>
                                                                            <div className="col-sm-8">
                                                                                <input type="number"
                                                                                    className="form-control"
                                                                                    id="budget"

                                                                                    value={subHead.budget}
                                                                                    onChange={(e) => handleSubHeadChange(i, 'budget', e.target.value)}
                                                                                    placeholder="₹ Enter Budget Amount" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label htmlFor="inputBudget"
                                                                                className="col-sm-4 col-form-label">Provisional
                                                                                Budget {i + 1}
                                                                                :</label>
                                                                            <div className="col-sm-8">
                                                                                <input type="number"
                                                                                    className="form-control"
                                                                                    id="provisional_budget"

                                                                                    value={subHead.provisional_budget}
                                                                                    onChange={(e) => handleSubHeadChange(i, 'provisional_budget', e.target.value)}
                                                                                    placeholder="₹ Enter Provisional Budget Amount" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {formData.is_activity_active == '1' && formData.monitoring_level == '1' && <div className="row">
                                                                        <table className="table table-bordered">
                                                                            <thead className="text-center-custom">
                                                                                <tr>
                                                                                    <th scope="col" width="150px">Activity</th>
                                                                                    <th scope="col" width="150px">Subactivity</th>
                                                                                    <th scope="col" width="300px">Opening Provision</th>
                                                                                    <th scope="col" width="300px">Opening Release</th>
                                                                                    <th scope="col" width="150px">Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="text-center-custom">
                                                                                {subHead?.activity.map((activityData, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>
                                                                                            <select
                                                                                                className="form-control"
                                                                                                name='activity_id'
                                                                                                value={activityData.activity_id}
                                                                                                onChange={(e) => handleActivityChange(i, index, e)}>
                                                                                                <option value="">---Select---</option>
                                                                                                {activity.map(e => (
                                                                                                    <option key={e.id} value={e.id}>{e.activity}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </td>
                                                                                        <td>
                                                                                            <select
                                                                                                className="form-control"
                                                                                                name='subactivity_id'
                                                                                                value={activityData.subactivity_id}
                                                                                                onChange={(e) => handleActivityChange(i, index, e, activityData.activity_id)}>
                                                                                                <option value="">---Select---</option>
                                                                                                {findSubActivitiesById(activityData.activity_id).map(e => (
                                                                                                    <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                name='provisional_budget'
                                                                                                placeholder="Enter Provision Budget"
                                                                                                value={activityData?.provisional_budget}
                                                                                                onChange={(e) => handleSubheadBudgetFormData(i, index, e)}
                                                                                                disabled={subHead.provisional_budget <= 0}
                                                                                            ></input>
                                                                                        </td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                name='budget'
                                                                                                placeholder="Enter Release Budget"
                                                                                                value={activityData?.budget}
                                                                                                disabled={subHead.budget <= 0}
                                                                                                onChange={(e) => handleSubheadBudgetFormData(i, index, e)}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <button className="btn btn-primary mx-2" onClick={(e) => addNewActivity(i)}>
                                                                                                <AddIcon />
                                                                                            </button>
                                                                                            <button type="button"
                                                                                                className="btn btn-danger mx-2"
                                                                                                onClick={(e) => deleteNewActivity(i, index)}>
                                                                                                <DeleteForever /></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditSchemeForm
