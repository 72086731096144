import React, {useState} from 'react'
import Tools from "../../components/Tools/Tools"
import ManageUser from '../../components/Users/ManageUser'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ManageDisbursmentHeader from '../../components/Disbursment/ManageDisbursmentHeader'

const Users = () => {
  const {authData}=useSelector((state)=>state.authData);
  const [searchStr, setToSearchStr] = useState('');
  const [ti, setToTier] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const handleDateChange = (start, end, dateColumn, searchStr, fy_id, tier, departmentId) => {
    setToSearchStr(searchStr);
    setToTier(tier);
    setDepartmentId(departmentId);
};
  return (
    <div>
      <Tools></Tools>
      <ManageDisbursmentHeader type="User" onDateChange={handleDateChange} />
      <div className='scheme p-2'>
        <ManageUser searchStr={searchStr} department_level={ti} departmentId={departmentId}></ManageUser>
      </div>
    </div>
  )
}

export default Users