import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
    Button, Box, TablePagination
} from '@mui/material';
import './Login.css';
import { get_my_departments, login_user_via_verify_otpV2, setAuthToken, switch_department } from '../../../helper/Api';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom'
import { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAIL } from '../../../RTK/Slices/AuthSlice';
import { useDispatch } from 'react-redux';
const DepartmentSwitch = () => {
    const [data, setData] = useState([])
    const [formData, setformData] = useState({
        "department_id": null,
    });
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const validateForm = () => {
        if (!formData.department_id) {
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            toast.error("All Fields Required", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }
        const loginResponse = await switch_department({ department_id: formData.department_id });
        if (loginResponse.data) {
            dispatch(LOGIN_SUCCESS(loginResponse.data));
            toast.success(loginResponse.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            sessionStorage.setItem("is_loggedIn", true);
            sessionStorage.setItem("userDetails", JSON.stringify(loginResponse.data));
            sessionStorage.setItem("token", JSON.stringify(loginResponse?.data?.token));
            const { token } = loginResponse?.data;
            setAuthToken(token);
            navigate('/');
        } else {
            toast.error(loginResponse.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };
    useEffect(() => {
        fetchDepartments();
    }, []);
    const fetchDepartments = async () => {
        try {
            const departments = await get_my_departments();
            if (departments.data.status) {
                setData(departments.data.departments);
            }
        } catch (error) {
            console.log("departments", error);
        }
    }
    const handleRadioChange = (event) => {
        const selectedId = event.target.value;
        setSelectedRow(selectedId);
        setformData((prevData) => ({
            ...prevData,
            department_id: selectedId,
        }));
    };
    return (
        <div className="login_bg_wrapper" style={{
            height: '100vh'
        }}>
            <Box sx={{
                maxWidth: 700,
                margin: 'auto',
                marginTop: 2,
                padding: 3,
                backgroundColor: 'white',
                boxShadow: 3,
                borderRadius: 2,
            }}>
                {/* Table Section */}
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead style={{ backgroundColor: '#1976d2' }}>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Choose Role</TableCell>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }} >User Role</TableCell>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Code</TableCell>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Name</TableCell>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Level</TableCell>
                               
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">
                                        <RadioGroup
                                            row
                                            value={selectedRow}
                                            onChange={handleRadioChange}
                                            style={{ justifyContent: 'center' }}>
                                            <FormControlLabel
                                                key={row.id}
                                                value={row.id}
                                                control={<Radio />}
                                            />

                                        </RadioGroup>
                                    </TableCell>
                                    <TableCell align="center">{row.user_type}</TableCell>
                                    <TableCell align="center">{row.code}</TableCell>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.department_level}</TableCell>
                                    
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={{
                    display: 'block',
                    margin: '0 auto',
                    padding: '10px 20px',
                    textAlign: 'center',
                    marginTop: 2,
                }}>
                    Submit
                </Button>
            </Box>
        </div>
    );
};
export default DepartmentSwitch;
