import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
    Button, Box, TablePagination
} from '@mui/material';
import './Login.css';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from 'react-redux';
import { get_my_departments, login_user_via_verify_otpV2, setAuthToken,switch_department} from '../../../helper/Api';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom'
import { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAIL } from '../../../RTK/Slices/AuthSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
const DepartmentOtp = () => {
    const [data, setData] = useState([])
    const [formData, setformData] = useState({
        "email": null,
        "otp": null,
        "department_id": null,
    });
    const [selectedRow, setSelectedRow] = useState(null);
    const handleChange = (event) => {
        const { name, value } = event.target;
        // console.log("testingDat==>>");
        setformData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    // console.log('auth', authData?.user);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const validateForm = () => {
        if (!formData.department_id || !formData.email || !formData.otp) {
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            toast.error("All Fields Required", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }
        const loginResponse = await login_user_via_verify_otpV2(formData);
        if (loginResponse.data.status) {
            dispatch(LOGIN_SUCCESS(loginResponse.data));
            toast.success(loginResponse.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            sessionStorage.removeItem("departments");
            sessionStorage.removeItem("email");
            sessionStorage.setItem("is_loggedIn", true);
            sessionStorage.setItem("userDetails", JSON.stringify(loginResponse.data));
            sessionStorage.setItem("token", JSON.stringify(loginResponse?.data?.token));
            const { token } = loginResponse?.data;
            setAuthToken(token);
            navigate('/');
        } else {
            toast.error(loginResponse.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };
    useEffect(() => {
        const departments = JSON.parse(sessionStorage.getItem("departments"));
        if (departments) {
            setData(departments)
        }
        const email = sessionStorage.getItem("email");
        if (email) {
            setformData((prevData) => ({
                ...prevData,
                email: email,
            }));
        }
    }, []);
    const handleRadioChange = (event) => {
        const selectedId = event.target.value;
        setSelectedRow(selectedId);
        setformData((prevData) => ({
            ...prevData,
            department_id: selectedId,
        }));
    };
    return (
        <div className="login_bg_wrapper" style={{
            height: '100vh'
        }}>
            <div className='row' style={{
                margin: 0
            }}>
                <div className="col-md-6 offset-md-3 mb-3">
                    <div className='otp-box' style={{
                        backgroundColor: '#f2f2f2',
                        padding: '20px',
                        borderRadius: '5px',
                        boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.2)',
                        marginTop: '20%',
                        marginBottom: '20px',
                        textAlign: 'center'
                    }}>
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead style={{ backgroundColor: '#1976d2' }}>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Choose Role</TableCell>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>User Role</TableCell>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Code</TableCell>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Name</TableCell>
                                <TableCell style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Level</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">
                                        <RadioGroup
                                            row
                                            value={selectedRow}
                                            onChange={handleRadioChange}
                                            style={{ justifyContent: 'center' }}>
                                            <FormControlLabel
                                                key={row.id}
                                                value={row.id}
                                                control={<Radio />}
                                            />

                                        </RadioGroup>
                                    </TableCell>
                                    <TableCell align="center">{row.user_type}</TableCell>
                                    <TableCell align="center">{row.code}</TableCell>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.department_level}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    sx={{
                        padding: 3,
                        border: '1px solid #ccc',
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',  // Center-align all items horizontally
                        gap: 3, // Space between elements
                    }}
                >

                    {/* OTP Input */}
                    <><TextField
                        margin="normal"
                        required
                        fullWidth
                        id="otp"
                        label="OTP"
                        type="number"
                        name="otp"
                        onChange={handleChange}
                        autoComplete="otp"
                        autoFocus
                        value={formData.otp}

                        sx={{ maxWidth: 300 }}
                    /></>
                </Box>
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={{
                    display: 'block',
                    margin: '0 auto',
                    padding: '10px 20px',
                    textAlign: 'center',
                    marginTop: 2,
                }}>
                    Submit
                </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DepartmentOtp;
