import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import {
    createAuthority,
    editActivity,
    get_states, get_districts_list
} from '../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import AddAuthorityPreview from '../Modal/AddAuthorityPreview';
const AddAuthority = () => {
    const { id } = useParams();
    const [showPreview, setShowPreview] = useState(false);
    const { authData } = useSelector((state) => state.authData);
    const [states_list, setStates_list] = useState([]);
    const [district_list, setDistrict_list] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        district: "",
        state: "",
        pincode: "",
        address: "",
        bank_name: "",
        branch: "",
        account_no: "",
        ifsc_code: "",
        created_by: authData.user.id,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleToggle = () => {
        setFormData({
            ...formData,
            status: formData.status === "0" ? "1" : "0",
        });
    }
    const handleFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
    }
    const previewHandler = (e) => {
        e.preventDefault();
        setShowPreview(true);
    };
    const validateForm = () => {
        if (
            !formData.name.trim() ||
            !formData.email.trim() ||
            !formData.mobile.trim() ||
            !formData.district.trim() ||
            !formData.state.trim() ||
            !formData.pincode.trim() ||
            !formData.address.trim() ||
            !formData.bank_name.trim() ||
            !formData.branch.trim() ||
            !formData.account_no.trim() ||
            !formData.ifsc_code.trim()
        ) {
            return false;
        }
        return true; // All fields are valid
    };
    useEffect(() => {
        get_state();
    },[]);
    useEffect(() => {
        get_districts();
    }, [formData.state]);
    const get_state = async () => {
        try {
            const states_response = await get_states();
            if (states_response.data.status) {
                setStates_list(states_response.data.list);
            } else {
                setStates_list([])
            }
        } catch (error) {
            console.log(error);
            setStates_list([])
        }
    }
    const get_districts = async () => {
        try {
            const { data } = await get_districts_list(formData.state);
            if (data.status) {
                setDistrict_list(data.list);
            } else {
                setDistrict_list([])
            }
        } catch (error) {
            setDistrict_list([])
        }
    }
    const handleSubmit = async (e) => {
        if (!validateForm()) {
            toast.success("All fields Required.", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }
        try {
            if (id) {
                const response = await editActivity({ activity: formData.activity, status: formData.status }, id);
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/department/activity');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            else {
                const response = await createAuthority(formData);
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/authoritys');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    return (
        <div>
            <AddAuthorityPreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData}
                                       handleSubmit={handleSubmit} states_list={states_list} district_list={district_list} ></AddAuthorityPreview>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>{id ? 'Edit Authority' : 'Add Authority'}</h4>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Name <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            name="name"
                                                            type='text'
                                                            value={formData.name}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Email <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            type='text'
                                                            name="email"
                                                            value={formData.email}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Email"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Mobile <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            name="mobile"
                                                            type='text'
                                                            value={formData.mobile}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Mobile"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        State <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <select
                                                            className="form-control"
                                                            name="state"
                                                            value={formData.state}
                                                            required
                                                            onChange={handleFormData}
                                                        >
                                                            <option value="">--Select state--</option>
                                                            {
                                                                states_list.map((state) => {
                                                                    return (<option value={state.id}
                                                                        key={state.id}>{state.name}</option>)
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        District <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <select
                                                            className="form-control"
                                                            name="district"
                                                            value={formData.district}
                                                            required
                                                            onChange={handleFormData}
                                                        >
                                                            <option value=''>--Select District--</option>
                                                            {district_list.map((district) => {
                                                                return (<option value={district.id}>{district.city}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Pincode <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            name="pincode"
                                                            type='number'
                                                            value={formData.pincode}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Pincode"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Address <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            name="address"
                                                            type='text'
                                                            value={formData.address}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Address"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Bank Name <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            name="bank_name"
                                                            type='text'
                                                            value={formData.bank_name}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Bank Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Branch <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            name="branch"
                                                            type='text'
                                                            value={formData.branch}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Branch"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Account No <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            name="account_no"
                                                            type='number'
                                                            value={formData.account_no}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Account No"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Ifsc Code <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            name="ifsc_code"
                                                            type='text'
                                                            value={formData.ifsc_code}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Ifsc Code"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 p-2 text-center">
                                                <Button variant="primary" onClick={previewHandler}>
                                                    SUBMIT
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )

};
export default AddAuthority;