import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import './sanctionOrderModal.css';

const AddAuthorityPreview = ({ showPreview, setShowPreview, formData, handleSubmit, states_list, district_list }) => {
    const handleClose = () => setShowPreview(false);
    return (
        <div>
            <Modal size="lg" show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Authority Preview : </Modal.Title>
                </Modal.Header>
                <Modal.Body className='previewModalBody'>
                    <div className="card p-3 mt-3">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td><strong>Name</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Email</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.email}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Mobile</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.mobile}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>State</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.state}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>District</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.district}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Pincode</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.pincode}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Address</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.address}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Bank Name</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.bank_name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Branch</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.branch}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Account No</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.account_no}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Ifsc Code</strong> <span className="text-danger">*</span>:</td>
                                            <td>{formData.ifsc_code}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Change
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default AddAuthorityPreview