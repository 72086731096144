import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddCommasToAmount } from "../../../helper/Utils";
import DataTable from "react-data-table-component";
import Tools from "../../../components/Tools/Tools";
import { Form } from "react-bootstrap";
import { ADD_CHALLAN_FAIL, ADD_CHALLAN_START } from "../../../RTK/Slices/ChallanSlice";
import { getAuthorities, getAuthoritieAccounts, getRoyaltyDetails, approveRoyalty ,getRoyaltyPaymentOtp} from "../../../helper/Api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ApprovalRoyaltyPreview from '../../../components/Modal/ApprovalRoyaltyPreview';
import RoyaltyPayment from './RoyaltyPayment';


const ViewRoyalty = () => {
    const { id } = useParams();
    const { authData } = useSelector((state) => state.authData);
    let { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data] = useState(state && state?.data ? state.data : []);
    const [showPreview, setShowPreview] = useState(false);
    const [approveCodition, setApproveCondition] = useState("");
    const [authorityAccounts, setAuthorityAccounts] = useState([]);
    const [authorities, setAuthorities] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [formData, setFormData] = useState({
        id: '',
        invoices: [],
        payment_order_no: '',
        amount: '',
        payment_order_date: '',
        doc_url: '',
        attachments: [],
        authority_id: '',
        authority_account_id: '',
        l2_remarks: "",
        l3_remarks: "",
        bankDetails:null
    });
    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Voucher No',
            selector: (row) => <b>{row.voucher_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Voucher\n' +
                'Date',
            selector: (row) => <b>{row.voucher_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'Transaction\n' +
                'Date',
            selector: (row) => <b>{row.amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Invoice No',
            selector: (row) => <b>{row.invoice_no}</b>,
            wrap: true,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Invoice Date',
            selector: (row) => <b>{row.invoice_date}</b>,
            wrap: true,
            sortable: true,
            width: "200px",

        },
        {
            name: 'Beneficiary\n' +
                'Name',
            selector: (row) => <b>{row.company_name || ''}</b>,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Approval\n' +
                'Type',
            selector: (row) =>
                <b>{row.payment_type === '1' ? "FULL PAYMENT" : row.payment_type === '2' ? 'PART PAYMENT' : ''}</b>,
            sortable: true,
            width: "250px",
            wrap: true,
        },
        {
            name: 'Taxable\n' +
                'Amount',
            selector: (row) => <b>{row.taxable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'INVOICE\n' +
                'AMOUNT',
            selector: (row) => <b>{row.payable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'INVOICE\n' +
                'Amount\n' +
                'Sanctioned',
            selector: (row) => <b>{row.sanction_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'Royalty Amount',
            selector: (row) => <b>{row?.royalty_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
    ];

    const calculateTotalAmount = (propertyName) => {
        let total = 0;
        data.forEach((row) => {
            const depositAmount = Number(parseFloat(row[propertyName]));
            if (!isNaN(depositAmount)) {
                total += depositAmount;
            }
        });
        return total.toFixed(2);
    };

    const totalInvoiceAmount = calculateTotalAmount('royalty_amount');

    const footerRow = {
        isFooter: true,
        selectableRowsSingle: false,
        invoice_no: <b>Total</b>,
        nps: <b>{AddCommasToAmount(totalInvoiceAmount)}</b>,
    };

    const updatedData = [...data, footerRow];

    useEffect(() => {
        fetchAuthorities();
        fetchRoyaltyDetail(id);
    }, [id]);

    const handleAttachmentChange = (event) => {
        const files = event.target.files;
        setFormData({
            ...formData,
            attachment: files
        });
    };

    const fetchAuthorityAccounts = async (id) => {
        try {
            const accounts = await getAuthoritieAccounts(id);
            if (accounts.data.status) {
                setAuthorityAccounts(accounts.data.result)
            }
        } catch (e) {

        }
    }

    const handleChallanFormData = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'authority_id') {
            await fetchAuthorityAccounts(value)
        }
        setFormData({ ...formData, [name]: value })
    }

    const fetchAuthorities = async () => {
        try {
            const accounts = await getAuthorities();
            if (accounts.data.status) {
                setAuthorities(accounts.data.result)
            }
        } catch (e) {

        }
    }
    const fetchRoyaltyDetail = async (id) => {
        try {
            const details = await getRoyaltyDetails(id);
            if (details.data.status) {
                setFormData(prevState => {
                    let updatedFormData = { ...prevState };
                    updatedFormData = { ...details.data.result };
                    updatedFormData.bankDetails=details.data.result.authorityAccountDetails
                    updatedFormData.attachments = [details.data.result.doc_url];
                    return updatedFormData;
                });
                console.log("testing",formData);
                if (details.data.result.invoices && details.data.result.invoices.length != 0) {
                    setInvoiceList(details.data.result.invoices);
                }
                fetchAuthorityAccounts(details.data.result.authority_id)
            }
        } catch (e) {
            dispatch(ADD_CHALLAN_FAIL('error getting while get Royalty Detail'));
        }
    }
    const previewhandler = (value,action) => {
        setShowPreview(value);
        setApproveCondition(action);
    };
    const handleSubmit = async (e) => {
        try {
            let details;
            if (authData.user.user_type === 'l2') {
                if (!formData.l2_remarks || formData.l2_remarks.trim() === '') {
                    toast.error("L2 remarks are required.", {position: toast.POSITION.TOP_CENTER});
                    return;
                }
                details = await approveRoyalty({
                    "payment_order_no": formData.payment_order_no,
                    "l2_approval":approveCodition,
                    "l2_remarks": formData.l2_remarks,
                });
                setFormData(prevState => {
                    let updatedFormData = { ...prevState };
                    updatedFormData.l2_approval = approveCodition
                    return updatedFormData;
                });
            } else {
                if (!formData.l3_remarks || formData.l3_remarks.trim() === '') {
                    toast.error("L3 remarks are required.", {position: toast.POSITION.TOP_CENTER});
                    return;
                }
                details = await approveRoyalty({
                    "payment_order_no": formData.payment_order_no,
                    "l3_approval":approveCodition,
                    "l3_remarks": formData.l3_remarks,
                });
                setFormData(prevState => {
                    let updatedFormData = { ...prevState };
                    updatedFormData.l2_approval = approveCodition
                    return updatedFormData;
                });
            }
            if (details.data.status) {
                setShowPreview(false);
                setApproveCondition('');
                navigate('/royalty_labour_approval');
            }

        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating challan'));
        }
    }
    return (
        <div>
            <ApprovalRoyaltyPreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData}
                handleSubmit={handleSubmit} invoiceList={invoiceList} authorities={authorities} authorityAccounts={authorityAccounts} />
            <Tools />
            <div className='addNewScheme'>
                <div className="add_new_user">
                    <h4>View Royalty</h4>
                </div>
            </div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={invoiceList}
                fixedHeader
                fixedHeaderScrollHeight="600px"
            />
            <div>
                <div className="p-3">
                    <div className="row">
                        <Form>
                            <div className="col-md-12">
                                <div className="card p-3 mt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputScheme" className="col-sm-4 col-form-label">
                                                            Payment Order No <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <input type="text" className="form-control" id="challan_no"
                                                                name='payment_order_no'
                                                                required
                                                                value={formData.payment_order_no}
                                                                disabled
                                                                placeholder="Enter Challan No" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputFinancials" className="col-sm-4 col-form-label">
                                                            Payment Order Date <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <Form.Control
                                                                type="date"
                                                                name="payment_order_date"
                                                                value={formData.payment_order_date}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="head_of_account_id" className="col-sm-4 col-form-label">
                                                            Authority <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control"
                                                                id="type" name='authority_id'
                                                                required
                                                                value={formData.authority_id}
                                                                disabled>
                                                                <option value="">Select Authority</option>
                                                                {
                                                                    authorities.map((st) => {
                                                                        return (
                                                                            <option value={st.id} key={st.id}>{st.name}</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputSchemee" className="col-sm-4 col-form-label">
                                                            Authority A/c <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control"
                                                                id="type" name='authority_account_id'
                                                                required
                                                                value={formData.authority_account_id}
                                                                disabled>
                                                                <option value="">Select Authority</option>
                                                                {
                                                                    authorityAccounts.map((st) => {
                                                                        return (
                                                                            <option value={st.id} key={st.id}>
                                                                                {st.bank_name} [{st.account_no}]
                                                                            </option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputSchemee" className="col-sm-4 col-form-label">
                                                            Challan Value <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <input type="text" className="form-control"
                                                                name='bsr_code'
                                                                required
                                                                disabled
                                                                placeholder="Enter Challan Value"
                                                                value={formData.amount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 p-2">
                                                    <div className="form-group row">
                                                        <div className="input-group">
                                                            <label className="col-sm-4 col-form-label">
                                                                View Challan<span className='text-danger'>*</span>:
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8" style={{ marginTop: '-30px', marginLeft: "185px" }}>
                                                            {
                                                                formData.attachments.map((att, i) => {
                                                                    return (
                                                                        <a className='p-1' href={att.file_url}
                                                                            target='_blank' rel="noopener noreferrer">Attachment {i + 1}</a>);
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    {authData.user.user_type == 'l2' ? <>
                                                        <div className="form-group">
                                                            <label className="col-form-label">L2 Remarks</label>
                                                            <textarea
                                                                className='form-control'
                                                                name="l2_remarks"
                                                                readOnly={authData.user.user_type !== 'l2'}
                                                                onChange={handleChallanFormData}
                                                                value={formData.l2_remarks}
                                                            ></textarea>
                                                        </div>
                                                    </> : ''
                                                    }
                                                    {
                                                        authData.user.user_type == 'l3' ? <>
                                                            <div className="form-group">
                                                                <label className="col-form-label">L3 Remarks</label>
                                                                <textarea
                                                                    className='form-control'
                                                                    name="l3_remarks"
                                                                    readOnly={authData.user.user_type !== 'l3'}
                                                                    onChange={handleChallanFormData}
                                                                    value={formData.l3_remarks}
                                                                ></textarea>
                                                            </div>
                                                        </> : ''
                                                    }
                                                </div>
                                                {(authData.user.user_type == 'l2' && formData.l2_approval == null) || (authData.user.user_type == 'l3' && formData.l3_approval == null) ? <div class="row mt-3 justify-content-center">
                                                    <div className="col-auto d-flex justify-content-center">
                                                        <button type="button" className="btn btn-primary mx-2" onClick={() => previewhandler(true,1)}>
                                                            Approve
                                                        </button>
                                                        <button type="button" className="btn btn-danger mx-2" onClick={() => previewhandler(true,0)}>
                                                            Reject
                                                        </button>
                                                    </div>
                                                </div> : ''}
                                                <div className="col-auto justify-content-center" style={{ marginTop: "50px" }}>
                                                    {((formData.l2_approval == 1 && authData.user.user_type == 'l2') || (formData.l3_approval == 1 && authData.user.user_type == 'l3')) && <button type="button" className='btn btn-primary mx-2' disabled="true">Already Approved</button>}
                                                    {((formData.l2_approval == 0 && authData.user.user_type == 'l2') || (formData.l3_approval == 0 && authData.user.user_type == 'l3')) && <button type="button" className='btn btn-danger mx-2' disabled="true">Already Rejected</button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {authData.user.user_type !== 'l1' && formData.l2_approval==1 &&formData.l3_approval==1 && <div >
                                      <RoyaltyPayment data={formData}></RoyaltyPayment>
                                    </div>}
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewRoyalty
