import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, Table } from 'react-bootstrap';
// import './sanctionOrderModal.css';
import '../BudgetAllocation/AllocateBudget.css';
import {
    getSubActivity
} from '../../../helper/Api';
const AddBudgetPreview = ({ showPreview, setShowPreview, formData, handleSubmit, scheme, subheadsList, financialYears, department, activity, subActivity, subheadBudgetTotal, pageType }) => {
    console.log("approve payment==>",formData);
    const handleClose = () => setShowPreview(false);
    const get_subhead_details = (id) => {
        if (id) {
            const filter = subheadsList.filter((subhead) => subhead.id == id);
            if (filter.length > 0) {
                // console.log(filter);
                return filter[0];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    const get_financial_year = (id) => {
        if (id) {
            const filter = financialYears.filter((fy) => fy.id == id);
            if (filter.length > 0) {
                return filter[0];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    return (
        <div>
            <Modal
                size="xl"
                show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Allocate Budget Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className='previewModalBody'>
                    <Row className="align-items-center">
                        <Col sm={6} className="p-2">
                            <Form.Group as={Row} controlId="provisionalBudgetInput">
                                <Form.Label column sm="6"><b>Budget Order Ref No.</b>:</Form.Label>
                                <Col sm="6">
                                    <Form.Control
                                        type="text"
                                        value={formData.budget_order_no}
                                        readOnly={true}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={6} className="p-2">
                            <Form.Group as={Row} controlId="releaseBudgetInput">
                                <Form.Label column sm="6"><b>Budget Order Date.</b>:</Form.Label>
                                <Col sm="6">
                                    <Form.Control
                                        type="date"
                                        value={formData.budget_order_date}
                                        readOnly={true}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col sm={6} className="p-2">
                            <Form.Group as={Row} controlId="provisionalBudgetInput">
                                <Form.Label column sm="6"><b>Provisioned Amount.</b>:</Form.Label>
                                <Col sm="6">
                                    <Form.Control
                                        type="text"
                                        value={formData.provision}
                                        readOnly={true}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={6} className="p-2">
                            <Form.Group as={Row} controlId="releaseBudgetInput">
                                <Form.Label column sm="6"><b>Released Amount.</b>:</Form.Label>
                                <Col sm="6">
                                    <Form.Control
                                        type="text"
                                        value={formData.release}
                                        readOnly={true}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Scheme Code.</th>
                                <th>Scheme Name</th>
                                <th>Financial Year</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{scheme?.code || ""}</td>
                                <td>{scheme?.name || ""}</td>
                                <td>{scheme?.financial_year || ""}</td>
                            </tr>
                        </tbody>
                    </Table>
                    {formData.department_Array.map((item, index) => (<div class="card p-3">
                        <>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="dropdown1" className='bold'>Select Department Code</label>
                                        <select class="form-control" id="dropdown1" value={formData.department_Array[index].department_id}>
                                            <option value="">---Select---</option>
                                            {department.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th className='bold text-center'>Level</th>
                                                <th className='bold text-center'>Department Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">{item.department_level}</td>
                                                <td className="text-center">{item.department_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {item.subHead.map((subhead, subheadIndex) => (<div class="card p-3">
                                    <div className="table-responsive custom-table">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Sub-Head Code</th>
                                                    <th scope="col">Sub-Head Name</th>
                                                    <th scope="col">Provisioned</th>
                                                    <th scope="col">Released</th>
                                                    <th scope="col">Under Approval</th>
                                                    <th scope="col">Sanctioned</th>
                                                    <th scope="col">Released Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr key={subheadIndex}>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            name='code'
                                                            value={subhead.subhead_id}
                                                        >
                                                            <option value="">---Select---</option>
                                                            {subheadsList.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.code}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>{subhead.name}</td>
                                                    <td>{subhead.provisional_budget | 0}</td>
                                                    <td>{subhead.budget | 0}</td>
                                                    <td>{subhead.utilised_budget - subhead.approveBudget | 0}</td>
                                                    <td>0</td>
                                                    <td>{Number(subhead.balance) + Number(subhead.approveBudget) | 0}</td>
                                                </tr>
                                                {scheme.is_activity_active=="1" && scheme.monitoring_level=='1' && <tr>
                                                    <td colSpan="8">
                                                        <div className='row'>
                                                            <div className="table-responsive custom-table">
                                                                <table className="table table-bordered">
                                                                    <thead className="text-center-custom">
                                                                        <tr>
                                                                            <th scope="col">Activity</th>
                                                                            <th scope="col">Subactivity</th>
                                                                            <th scope="col">Provisional Budget</th>
                                                                            <th scope="col">Provisioned Allocation</th>
                                                                            <th scope="col">Release Budget</th>
                                                                            <th scope="col">Released Allocation</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="text-center-custom">
                                                                        {subhead.activity && subhead.activity.length!=0 && subhead.activity.map((activityData, activityIndex) => (
                                                                            <tr key={activityIndex}>
                                                                                <td>
                                                                                    <select
                                                                                        className="form-control"
                                                                                        name='activity_id'
                                                                                        value={activityData.activity_id}
                                                                                        disabled={true}
                                                                                    >
                                                                                        <option value="">---Select---</option>
                                                                                        {activity.map(activity => {
                                                                                            const exists = subhead.activities.find(e => e.subhead_activity_id == activity.id);
                                                                                            if (exists) {
                                                                                                return (
                                                                                                    <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <select
                                                                                        className="form-control"
                                                                                        name='subactivity_id'
                                                                                        value={activityData.subactivity_id}
                                                                                        disabled={true}
                                                                                    >
                                                                                        <option value="">---Select---</option>
                                                                                        {findSubActivitiesById(activityData.activity_id).map(e => {
                                                                                            const exists = subhead.activities.find(ea => ea.subhead_activity_id == activityData.activity_id && ea.subhead_subactivity_id == e.id);
                                                                                            if (exists) {
                                                                                                return (
                                                                                                    <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        value={activityData.subhead_provisional_budget | 0}
                                                                                        disabled={true}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        name='provisional_budget'
                                                                                        placeholder="Enter Provision Budget"
                                                                                        value={activityData.provisional_budget}
                                                                                        disabled={true}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        value={Number(activityData.subhead_budget) + Number(activityData.budget) | 0}
                                                                                        disabled={true}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        name='budget'
                                                                                        placeholder="Enter Budget"
                                                                                        value={activityData.budget}
                                                                                        disabled={true}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <div className="table-responsive custom-table">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th></th>
                                                                                <th scope="col">Provision</th>
                                                                                <th scope="col">Release</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td>Subhead Initial</td>
                                                                                <td>{subhead.provisional_budget | 0}</td>
                                                                                <td>{Number(subhead.balance) + Number(subhead.approveBudget) | 0}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td>Total Allocation</td>
                                                                                <td>{subhead?.allocate_provision | 0}</td>
                                                                                <td>{subhead?.allocate_release | 0}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td>Budget Balance</td>
                                                                                <td>{subhead.provisional_budget - subhead?.allocate_provision | 0}</td>
                                                                                <td>{(Number(subhead.balance) + Number(subhead.approveBudget)) - subhead?.allocate_release | 0}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>))}
                            </div>
                        </>
                    </div>))}
                    <br></br>
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <table className="table table-bordered mx-auto w-90">
                                <thead className="text-center-custom">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Provisioned</th>
                                        <th scope="col">Released</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center-custom">
                                    <tr>
                                        <td className='bold'>Initial Scheme Balance</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name='provisionalBudget'
                                                readOnly={true}
                                                value={scheme.provisional_budget}
                                                placeholder="Enter Provision Budget" />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name='releasedBudget'
                                                readOnly={true}
                                                value={scheme.balance + Number(formData.total_scheme_released_allocation)}
                                                placeholder="Enter Release Budget" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bold'>Total Allocation of Scheme</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name='provisionalBudget'
                                                readOnly={true}
                                                value={subheadBudgetTotal.current.allocateProvision || 0}
                                                placeholder="Enter Provision Budget" />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name='releasedBudget'
                                                readOnly={true}
                                                value={subheadBudgetTotal.current.allocateRelease || 0}
                                                placeholder="Enter Release Budget" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='bold'>Scheme Balance</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name='provisionalBudget'
                                                readOnly={true}
                                                value={scheme.provisional_budget - subheadBudgetTotal.current.allocateProvision || 0}
                                                placeholder="Enter Provision Budget" />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name='releasedBudget'
                                                readOnly={true}
                                                value={(scheme.balance + Number(formData.total_scheme_released_allocation)) - subheadBudgetTotal.current.allocateRelease || 0}
                                                placeholder="Enter Release Budget" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    {pageType == 'View' && <><Button variant="danger" onClick={() => handleSubmit(0)}>
                        Reject
                    </Button>
                        <Button variant="primary" onClick={() => handleSubmit(1)}>
                            Approve
                        </Button></>}
                    {pageType == 'Add' && <Button variant="primary" onClick={() => handleSubmit()}>
                        Submit
                    </Button>}
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default AddBudgetPreview