import React, { useState, useEffect } from 'react'
import Tools from '../../../components/Tools/Tools'
import ManageDisbursmentHeader from '../../../components/Disbursment/ManageDisbursmentHeader'
import {Tab, Tabs} from 'react-bootstrap';
import RoyaltyApprovalList from '../../../components/Reconciliation/Royalty/RoyaltyApprovalList';
import LabourCessApprovalList from '../../../components/Reconciliation/LabourCess/LabourCessApprovalList';
import RoyaltyReportList from '../../../components/Reconciliation/Royalty/RoyaltyReportList';
import LabourCessReportList from '../../../components/Reconciliation/LabourCess/LabourCessReportList';
import RoyaltyPaymentList from '../../../components/Reconciliation/Royalty/RoyaltyPaymentList';
import LabourCessPaymentList from '../../../components/Reconciliation/LabourCess/LabourCessPaymentList';

export default function RoyaltyApproval() {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateColumn, setDateColumn] = useState('reimbursement_order_date');
    const [searchStr, setToSearchStr] = useState('');
    const [page, setPage] = useState('approve');
    const [heading, setHeading] = useState('');
    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);
    };
    const handleTabSelect = (key) => {
        if (key === 'approve') {
            setHeading("Royalty Approval");
            setPage("approve");
        } else if(key === 'dbt_payment') {
            setHeading("Labour Cess Approval");
            setPage("dbt_payment");
        } else if(key === 'royalty_challans') {
            setHeading("Royalty Challans");
            setPage("royalty_challans");
        }else if(key === 'labour_challans') {
            setHeading("Labour Challans");
            setPage("labour_challans");
        }
        else if(key === 'roaylty_payment') {
            setHeading("Roaylty Payment");
            setPage("roaylty_payment");
        }
        else if(key === 'labourcess_payment') {
            setHeading("Labour Cess Payment");
            setPage("labourcess_payment");
        }
    };
    useEffect(() => {
        handleTabSelect('approve');
    }, []);
    return (
        <div>
            <Tools />
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumnType={dateColumn} heading={heading} type="Royalty" />
            <div className='scheme p-2'>
                    <Tabs
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    activeKey={page}
                    onSelect={handleTabSelect}
                >
                    {<Tab eventKey="approve" title="Roaylty Approval" className='p-2'>
                        <RoyaltyApprovalList fromDate={fromDate} toDate={toDate} searchStr={searchStr} />
                    </Tab>}
                    {<Tab eventKey="roaylty_payment" title="Roaylty Payment" className='p-2'>
                        <RoyaltyPaymentList fromDate={fromDate} toDate={toDate} searchStr={searchStr}></RoyaltyPaymentList>
                    </Tab>}
                    {<Tab eventKey="royalty_challans" title="Roaylty Challans" className='p-2'>
                        <RoyaltyReportList fromDate={fromDate} toDate={toDate} searchStr={searchStr}/>
                    </Tab>}
                    {<Tab eventKey="dbt_payment" title="Labour Cess Approval" className='p-2'>
                        <LabourCessApprovalList fromDate={fromDate} toDate={toDate} searchStr={searchStr} />
                    </Tab>}
                    {<Tab eventKey="labourcess_payment" title="Labour Cess Payment" className='p-2'>
                        <LabourCessPaymentList fromDate={fromDate} toDate={toDate} searchStr={searchStr}></LabourCessPaymentList>
                    </Tab>}
                    {<Tab eventKey="labour_challans" title="Labour Cess Challans" className='p-2'>
                        <LabourCessReportList fromDate={fromDate} toDate={toDate} searchStr={searchStr}/>
                    </Tab>}
                </Tabs>
            </div>
        </div>
    )
}