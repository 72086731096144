import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { delete_budget_by_id, getAllocateBudgetList } from '../../../helper/Api';
import DataTable from 'react-data-table-component';
import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';
import './BudgetAllocationList.css';
const BudgetAllocationApprovalList = () => {
    const { authData } = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('All');
    const fetchBudgets = async (payload) => {
        try {
            const { data } = await getAllocateBudgetList(payload);
            setData(data.result);
        } catch (error) {
            setData([]);
            console.log(error);
        }
    }
    useEffect(() => {
        fetchBudgets({
            pending: true,
            department_id: authData.user.department_id
        });
    }, [])
    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };
    const columns = [
        {
            name: 'L2 Approval',
            selector: (row) => row.l2_status == null ? "Pending" : row.l2_status == 1 ? "Approved" : "Rejected",
            center: true
        },
        {
            name: 'L3 Approval',
            selector: (row) => row.l3_status == null ? "Pending" : row.l3_status == 1 ? "Approved" : "Rejected",
            center: true
        },
        {
            name: 'Budget Order No',
            selector: (row) => <b>{row.budget_order_no}</b>,
            width: "150px",
            center: true

        },
        {
            name: 'Budget Date',
            selector: (row) => {
                return (<b>{row.budget_order_date}</b>);
            },
            sortable: true,
            width: "150px",
            center: true,
        },
        {
            name: 'Provision Budget',
            selector: (row) => {
                return (<b>{row.total_scheme_provisional_allocation}</b>);
            },
            sortable: true,
            width: "200px",
            center: true
        },
        {
            name: 'Release Budget',
            selector: (row) => {
                return (<b>{row.total_scheme_released_allocation}</b>);
            },
            sortable: true,
            width: "200px",
            center: true
        },
        {
            name: 'Scheme Id',
            selector: (row) => row.scheme_id,
            wrap: true,
            sortable: true,
            width: "200px",
            center: true
        },
        {
            name: 'Scheme Name',
            selector: (row) => <b>{row.name}</b>,
            wrap: true,
            width: "300px",
        },
        {
            name: 'Total department',
            selector: (row) => <b>{row.total_departments}</b>,
            wrap: true,
            width: "150px",
            center: true
        },
        {
            name: 'Action',
            width: "200px",
            selector: (row) => {
                return (
                    <>
                        <div className='user_action'>
                            <span className='pr-4'><Link to={`/view-allocate-budget/${row.id}`}><AiOutlineEye /></Link></span>
                            {((Number(row.l2_status) === 0 || Number(row.l3_status) === 0) && authData.user.user_type === 'l1') && (
                                <span className='pr-4'>
                                    <Link to={`/edit-allocate-budget/${row.id}`}>
                                        <FaEdit />
                                    </Link>
                                </span>
                            )}
                            {/* {
                                show_l1_action_btn(authData.user.user_type, row.l2_status, row.l3_status) ?
                                    <span className='pr-4 text-danger'
                                        onClick={() => delete_budget(row.id)}><MdDelete></MdDelete></span> : ""
                            } */}
                        </div>
                    </>
                );
            },
        }
    ];
    const delete_budget = async (budget_id) => {
        if (budget_id) {
            const result = window.confirm("Are you sure you want to delete the budget?");
            if (result) {
                try {
                    let formData = {
                        "budget_id": budget_id
                    }
                    // console.log(data)
                    // const updated_data=data.splice(0,1);

                    //console.log(updated_data);
                    // return false;
                    const budget_response = await delete_budget_by_id(formData);
                    if (budget_response.data.status) {
                        let index = data.findIndex((u) => u.id === budget_id);


                        if (index >= 0) {
                            const updated_data = data.splice(index, 1);
                            fetchBudgets();
                        }
                        toast.success(budget_response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else {
                        toast.error(budget_response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                } catch (error) {
                    toast.error("getting error while delete user.", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } else {
            toast.error("budget id required.", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const DepartmentTable = ({ department, subhead }) => {
        const departmentcolumns = [
            {
                name: 'Code',
                selector: row => row.code,
                sortable: true
            },
            {
                name: 'Department Name',
                selector: row => row.name,
                sortable: true
            },
            {
                name: 'Provisional Budget',
                selector: row => row.provisional_budget || 0,
                sortable: true
            },
            {
                name: 'Release Budget',
                selector: row => row.balance || 0,
                sortable: true
            },
        ];
        const customStyles = {
            table: {
                style: {
                    border: '1px solid #ddd',
                    width: '800px',
                },
            },
            headRow: {
                style: {
                    backgroundColor: '#f8f9fa',
                    borderBottom: '2px solid #ddd',
                },
            },
            headCells: {
                style: {
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    borderRight: '1px solid #ddd',
                    fontWeight: "bold",
                    fontSize: "14px"
                },
            },
        };
        const subheadcolumns = [
            {
                name: 'Code',
                selector: row => row.code,

            },
            {
                name: 'SubHead Name',
                selector: row => row.name,

            },
            {
                name: 'Provisional Budget',
                selector: row => row.provisional_budget,

            },
            {
                name: 'Release Budget',
                selector: row => row.balance,

            },
        ];
        return (
            <div style={{ paddingLeft: '50px' }}> {/* Adjust padding as needed */}
                <DataTable
                    customStyles={customStyles}
                    columns={departmentcolumns}
                    data={department}
                    noHeader
                    pagination={false}
                />
                <DataTable
                    customStyles={customStyles}
                    columns={subheadcolumns}
                    data={subhead}
                    noHeader
                    pagination={false}
                />
            </div>
        );
    };
    const SubHeadTable = ({ subhead }) => {
        const columns = [
            {
                name: 'Code',
                selector: row => row.code,

            },
            {
                name: 'Name',
                selector: row => row.name,

            },
            {
                name: 'Provisional Budget',
                selector: row => row.provisional_budget,

            },
            {
                name: 'Release Budget',
                selector: row => row.balance,

            },
        ];
        const customStyles = {
            table: {
                style: {
                    border: '1px solid #ddd',
                    width: '800px',
                },
            },
            headRow: {
                style: {
                    backgroundColor: '#f8f9fa',
                    borderBottom: '2px solid #ddd',
                },
            },
            headCells: {
                style: {
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    borderRight: '1px solid #ddd',
                    fontWeight: "bold",
                    fontSize: "14px"
                },
            },
        };
        return (
            <div>
                <DataTable
                    customStyles={customStyles}
                    columns={columns}
                    data={subhead}
                    noHeader
                    pagination={false}
                />
            </div>
        );
    };
    const DepartmentComponent = ({ data }) => <DepartmentTable department={data.departments} subhead={data.subheads} />;
    const SubheadComponent = ({ data }) => <SubHeadTable subhead={data.subheads} />;
    const handleChangeFilter = (e) => {
        const newFilter = e.target.value;
        setFilter(newFilter);
        console.log(newFilter);
        if (newFilter === 'pending') {
            fetchBudgets({ pending: true });
        } else {
            fetchBudgets({});
        }
    };
    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}
export default BudgetAllocationApprovalList
