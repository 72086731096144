import Modal from 'react-bootstrap/Modal';

const StatusModal = ({showPreview, setShowPreview, approvalLogs, data}) => {
    const handleClose = () => {
        // handlePopUp(false);
        setShowPreview(false);
    }
    return  <div>
                <Modal
                size="lg"
                show={showPreview} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reimbursement Order No:  </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className='table table-responsive'>
                            <thead>
                                <tr>
                                    <th>Department Level</th>
                                    <th>L2 Approval</th>
                                    <th>L3 Approval</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.department_level}</td>
                                    <td>{data.l2_approval === '1' ? 'Approved' : data.l2_approval === '0' ? 'Rejected' : 'Pending'}</td>
                                    <td>{data.l3_approval === '1' ? 'Approved' : data.l3_approval === '0' ? 'Rejected' : 'Pending'}</td>
                                </tr>
                                {approvalLogs.map((log, idx) => (
                                    <tr key={idx}>
                                        <td>{log.department_level}</td>
                                        <td>{log.l2_approval === '1' ? 'Approved' : log.l2_approval === '0' ? 'Rejected' : 'Pending'}</td>
                                        <td>{log.l3_approval === '1' ? 'Approved' : log.l3_approval === '0' ? 'Rejected' : 'Pending'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>

                </Modal>
            </div>
}

export default StatusModal;